// Packages
import React, { Component } from 'react';
import { connect } from 'react-redux';
import http from '../../../https/api';
import axios from 'axios';
import { convertKeysToLowercase, decryptData, encryptData, getBase64, getCookie, processArrayAddress } from '../../../util/util';
import { ToastContainer, toast } from 'react-toastify';
import * as moment from 'moment';
import 'react-toastify/dist/ReactToastify.css';
import BtnRed from '../../../components/btn_submit/indexRed';
import {
  activeMenuEntrega
} from "../../../appRedux/actions/Setting";

import {
  setpurchasesData,
  showLoader,
  hideLoader,
  getPurchaseProducts,
  deliveryQualification,
  deliveryQualificationResult,
  assemblyQualification,
  assemblyQualificationResult,
  productQualification,
  productQualificationResult
} from "../../../appRedux/actions/Purchases";

// Styles
import './warranty.scss';

// Images & Icons
import loadingCircle from '../../../assets/images/loading-circle-red.gif';
import sillaCompleta from '../../../assets/images/silla-completa.png';
import sillaBase from '../../../assets/images/silla-base.png';
import sillaBasePatas from '../../../assets/images/silla-base-patas.png';
import sillaPatas from '../../../assets/images/silla-patas.png';
import sillaPata from '../../../assets/images/silla-patas2.png';
import bioSecurity from '../../../assets/images/bioseguridad.png';
import despedida from '../../../assets/images/despedida.png';
import imgNotLoad from '../../../assets/images/img_not_load.png';
import imgCalServ from '../../../assets/images/img_calificacion.jpg';
import imgCalArmado from '../../../assets/images/calificacion_armado.png';

import GeneradaIcon from "../../../assets/images/state/generada.svg";
import ConfirmadaIcon from "../../../assets/images/state/confirmada.svg";
import AlistamientoIcon from "../../../assets/images/state/alistamiento.svg";
import RutaIcon from "../../../assets/images/state/ruta.svg";
import EntregadaIcon from "../../../assets/images/state/entregada.svg";
import EstadoArmadoIcon from "../../../assets/images/icons/estadoarmado.svg";
import WhatsappImg from "../../../assets/images/icons/whatsapp.svg";


// Material UI
import { Button, TextField, InputAdornment, Icon, Checkbox, InputLabel, MenuItem, FormControl, Select, FormControlLabel } from '@material-ui/core';
import { Fade, Backdrop, Modal, Box, Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import Rating from '@material-ui/lab/Rating';
import { Alert } from '@material-ui/lab';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import RoomIcon from '@material-ui/icons/Room';
import { Build, Description, Star } from '@material-ui/icons';

// Variables
import { LOADING_COMBO, COMBO_INTERIOR_1, COMBO_INTERIOR_2, DIR_SELECT } from './variableSelects';

import Pagination from "rc-pagination";
import 'rc-pagination/assets/index.css';

import { uploadFile } from 'react-s3';
import { SantiPuntos } from '../../../components/PuntosPagaGana';

class Warranty extends Component {

  constructor(props) {
    super(props);
    // OBTENER DATOS USUARIOS EN SESIÓN.
    let { userData } = props;
    this.state = {
      acordionExpanded: false,
      openModalQualification: false,
      openModalQualificationDelivery: false,
      openModalQualificationAssembly: false,
      openModalQualificationSummary: false,
      openModalShowMotNoCumplimiento: false,
      loadingQualitication: false,
      messageError: {},
      globalLoading: true,
      btnLoading: false,
      userData: userData, //Datos del usuario logueado
      CURRENT_PAGE: "PRODUCT_LIST",
      checkboxAcceptTermsAndConditions: false,
      showAlertTermsAndConditions: false,
      showAlertTermsTracking: false,
      checkboxAcceptTermsTracking: false,
      showAlertTermsAssembly: false,
      checkboxAcceptTermsAssembly: false,
      NUM_SERVICE: "",
      problemDescription: {
        quePaso: "",
        queTieneElProducto: "",
        enQueParte: "",
        dondeSeEncuentra: "",
      },
      filtersWarranty: {
        showFilters: false,
        filterInProcess: true,
        filterAvailable: true,
        filterNoAvailable: true
      },
      purchasesListToShow: [], //Lista de compras que se muestra cuando aplican los filtros
      purchasesList: [], //Lista de compras
      productListToShow: [], //Lista de productos que se muestra cuando aplican los filtros
      productList: [], //Lista de productos
      productSelected: {}, //Productos seleccionado para el proceso de garantía
      orderSelected: {},
      detailSelect: [],
      pagination: {
        page: 1,
        count: 0,
        pageSize: 5,
      },
      filterText: "",
      DIRECTION_TO_RETURN: "",
      selectDirections: {
        selectDirectionsArray: [],
        selectDirectionsSelect: {}
      },
      idTecnico: "",
      dateDiagnostic: {
        dateDiagnosticArray: [],
      },
      dateDiagnosticSelect: {},
      selectDepartment: {
        selectDepartmentArray: [],
        selectDepartmentSelect: {}
      },
      selectCity: {
        selectCityArray: [],
        selectCitySelect: {}
      },
      selectNeighborhood: {
        selectNeighborhoodArray: [],
        selectNeighborhoodSelect: {}
      },
      selectIndexDirection: {
        selectIndexDirectionArray: DIR_SELECT,
        selectIndexDirectionSelect: DIR_SELECT[0]
      },
      selectInterior1: {
        selectInterior1Array: COMBO_INTERIOR_1,
        selectInterior1Select: COMBO_INTERIOR_1[0]
      },
      selectInterior2: {
        selectInterior2Array: COMBO_INTERIOR_2,
        selectInterior2Select: COMBO_INTERIOR_2[0]
      },
      newDirection: {
        newDirectionFlag: false,
        via: "",
        crucero: "",
        placa: "",
        nInterior1: "",
        nInterior2: "",
        senia: "",
        resultDirection: "",
      },
      photos: {
        photo_01: {
          file: null,
          name: 'Subir Imagen'
        },
        photo_02: {
          file: null,
          name: 'Subir Imagen'
        },
        photo_03: {
          file: null,
          name: 'Subir Imagen'
        },
        photo_04: {
          file: null,
          name: 'Subir Imagen'
        },
        photo_05: {
          file: null,
          name: 'Subir Imagen'
        }
      },
      deliveryClf: {
        value: null,
        observation: null,
        ref: null,
        op: null
      },
      assemblyClf: {
        value: null,
        observation: null,
        ref: null,
        op: null,
        num_orden: null
      },
      productClf: {
        value: null,
        observation: null,
        ref: null,
        op: null,
        num_orden: null
      },
      openModalWhatsappWarning: false,
      ps_vcNumServ: null,
      ps_vcDescripcion: null,
      virtual: false,
      userPhones: [],
      userPhone: '',
      userMail: '',
      disponibleSf: false,
      tipoGarantiaSf: "",
      tokenSagicc: ''
    }
    this.handleCloseModalQualificationDelivery = this.handleCloseModalQualificationDelivery.bind(this);
    this.handleCloseModalQualificationAssembly = this.handleCloseModalQualificationAssembly.bind(this);
    this.handleCloseModalQualification = this.handleCloseModalQualification.bind(this);
  }

  componentDidMount() {
    // 
    this.getPurchases();
    this.setState({ tokenSagicc: process.env.REACT_APP_TOKEN_SAGICC });
  }

  /**función que valida la compañia en que se encuentra, para seguir el flujo de garantía */
  validPanama = (product) => {
    if (this.state.userData.company === 'JP') {
      this.goToChat();
    } else {
      this.selectProduct(product);
      this.changeCurrentPage("TERMS_CONDITIONS");
    }
  }

  /**
   * 
   * Ir al chat de ayuda
   */
  goToChat = () => {
    //
    if (this.state.userData.id) {
      this.setState({ ps_vcNumServ: null, ps_vcDescripcion: null });
      // VOLVER AL LISTADO.
      this.changeCurrentPage("PRODUCT_LIST");
      // NUMBER.
      const whatsapp = (this.state.userData.company === 'JA') ? '573123683461' : '507-66785136';
      // REDIRRECCIÓN A WHATSAPP
      window.open(`https://api.whatsapp.com/send?phone=${whatsapp}&text=Hola,%20me%20puedes%20ayudar?`, '_blank');
    }
  }

  /**
   * Genera la dirección a mandar al servicio 
   * a partir de los datos ingresador por el usuario
   */
  generateDirection = (event) => {
    event.preventDefault();
    let directionToReturn = "";
    const newDirection = this.state.newDirection;
    directionToReturn += this.state.selectIndexDirection.selectIndexDirectionSelect.codigo + "  ";
    directionToReturn += newDirection.via + "  ";
    directionToReturn += newDirection.crucero + "  ";
    directionToReturn += newDirection.placa + "  ";
    if (this.state.selectInterior1.selectInterior1Select && this.state.selectInterior1.selectInterior1Select.codigo && this.state.selectInterior1.selectInterior1Select.codigo !== '-2') {
      directionToReturn += this.state.selectInterior1.selectInterior1Select.codigo + "  ";
      directionToReturn += newDirection.nInterior1 + "  ";
    }
    if (this.state.selectInterior2.selectInterior2Select && this.state.selectInterior2.selectInterior2Select.codigo && this.state.selectInterior2.selectInterior2Select.codigo !== '-2') {
      directionToReturn += this.state.selectInterior2.selectInterior2Select.codigo + "  ";
      directionToReturn += newDirection.nInterior2 + "  ";
    }
    directionToReturn = directionToReturn.substr(0, directionToReturn.length - 2);
    this.setState({
      DIRECTION_TO_RETURN: directionToReturn
    });
    this.changeCurrentPage(this.state.virtual ? "EMAIL_SELECT" : "DATE_DEFAULT")
  }

  /**
   * Limpia todas las variables para iniciar otro proceso de garantía
   */
  resetVariables() {
    this.setState({
      checkboxAcceptTermsAndConditions: false,
      NUM_SERVICE: "",
      DIRECTION_TO_RETURN: "",
      problemDescription: {
        quePaso: "",
        queTieneElProducto: "",
        enQueParte: "",
        dondeSeEncuentra: "",
      },
      newDirection: {
        newDirectionFlag: false,
        via: "",
        crucero: "",
        placa: "",
        nInterior1: "",
        nInterior2: "",
        senia: "",
        resultDirection: "",
      },
      photos: {
        photo_01: {
          file: null,
          name: 'Subir Imagen'
        },
        photo_02: {
          file: null,
          name: 'Subir Imagen'
        },
        photo_03: {
          file: null,
          name: 'Subir Imagen'
        },
        photo_04: {
          file: null,
          name: 'Subir Imagen'
        },
        photo_05: {
          file: null,
          name: 'Subir Imagen'
        }
      }
    });
  }

  getUserPhones = async () => {
    this.setState({
      globalLoading: true
    })
    let urlBase = process.env.REACT_APP_JAMAR;
    let token = getCookie('token');
    let formData = {
      company: this.state.userData.company,
      n_ide: encryptData(this.props.userData.document)
    }
    const res = await http.post(`${urlBase}listNumbersContact`, formData, { headers: { 'Authorization': `Bearer ${token}` } });
    if (res?.data?.length === 0) {
      this.changeCurrentPage("ADD_PHONE");
      this.setState({
        globalLoading: false
      });
    } else {
      this.setState({
        userPhones: res?.data?.map(phone => phone ? decryptData(phone) : ''),
        userPhone: res?.data[0] ? decryptData(res?.data[0]) : '',
        globalLoading: false
      });
    }
  }

  /**
   * Establecer la pagina a cambiar en el "wizard"
   * @param {*} page 
   */
  changeCurrentPage(page, virtual = false) {
    if (page) {
      window.scrollRef.scrollTo({
        top: 0,
        behavior: "smooth"
      });
      this.setState({ CURRENT_PAGE: page });
      switch (page) {
        case "PRODUCT_LIST":
          this.selectProduct({});
          this.resetVariables();
          break;
        case "DATE_DEFAULT":
          this.getDateDefault();
          break;
        case "SET_DATE_DIAGNOSTIC":
          this.getDateDiagnostic();
          break;
        case "SELECT_DIRECTIONS":
          this.setState({
            virtual,
            newDirection: {
              ...this.state.newDirection,
              newDirectionFlag: false
            }
          });
          this.getDirections();
          break;
        case "NEW_DIRECTION":
          this.setState({
            newDirection: {
              ...this.state.newDirection,
              newDirectionFlag: true
            }
          });
          this.getDepartment();
          break;
        case "TERMS_CONDITIONS_TRACKING":
          this.props.activeMenuEntrega(true);
          break;
        case "PHONE_SELECT":
          this.getUserPhones();
          break;
        case "ADD_PHONE":
          this.setState({
            userPhone: "",
          })
          break;
        case "EMAIL_SELECT":
          this.setState({
            userMail: this.state.userData.email,
          })
          break;
        case "ADD_EMAIL":
          this.setState({
            userMail: "",
          })
          break;
        default:
          break;
      }
    }
  }

  /**
   * Actualizar filtro de text
   * @param {*} event obj evento
   */
  handleExpandAcordion = (params) => (event, isExpanded) => {
    // VALID IS EXPANDED.
    if (isExpanded) {
      // OBTENER PRODUCTOS DE LA COMPRA.
      this.props.getPurchaseProducts({
        userData: this.props.userData,
        purchases: this.props.purchasesData,
        purchase: params
      });
    }
    this.setState({
      acordionExpanded: isExpanded ? params.num_orden : false
    });
  }

  /**
   * Seleccionar el producto
   * @param {*} product 
   */
  selectProduct(product) {
    this.setState({ productSelected: product });
  }
  /**
   * Seleccionar la compra
   * @param {*} order 
   */
  selectOrder(order) {
    this.setState({ orderSelected: order });
  }

  /**
   * Obtiene la lista de productos de una compra
   * @param {*} purchase compra
   */
  getProductsFromPurchase(purchase) {
    // this.setState({ globalLoading: true });
    return new Promise((resolve, reject) => {
    });
  }

  /**
   * Obtiene la lista de compras
   */
  getPurchases() {
    // VALIDAR SI YA SE CARGO LA INFO.
    if (this.props.purchasesData.length < 1) {
      // LONDING.
      this.props.showLoader();
      // EJECUTAR SERVICIO PARA OBTENER LAS COMPRAS.
      this.props.setpurchasesData(this.props.userData);
    }

    let time = null;
    // OBTENER VALOR.
    time = setInterval(() => {
      if (time && !this.props.loader) {
        // 
        if (this.props.expandedPurchase) {
          this.setState({
            acordionExpanded: this.props.expandedPurchase.num_orden
          });
          // VALID
          let valid = true;
          // 
          for (const purchase of this.props.purchasesData) {
            if (valid) {
              const qualificationModalAlreadySeenString = localStorage.getItem("qualificationModalAlreadySeen");
              const qualificationModalAlreadySeen = JSON.parse(qualificationModalAlreadySeenString);
              // VALIDAR ESTADO DE LA ENTREGA Y SI ESTA SIN CALIFICAR.
              if (!qualificationModalAlreadySeen && purchase.estado === 'ENTREGADA' && !purchase.has_delivery_calification) {
                // 
                this.setState({
                  openModalQualificationDelivery: true,
                  openModalQualificationSummary: true,
                  deliveryClf: {
                    ...this.state.deliveryClf,
                    ref: purchase.num_orden,
                    op: purchase.consec_orden
                  }
                });
                valid = false;
              }
              // VALIDAR ESTADO DE LA ARMADO Y SI ESTA SIN CALIFICAR.
              const deliveryModalAlreadySeenString = localStorage.getItem("deliveryModalAlreadySeen");
              const deliveryModalAlreadySeen = JSON.parse(deliveryModalAlreadySeenString);
              if (!deliveryModalAlreadySeen && purchase.estado_armado === 'REALIZADO' && purchase.aplica_armado === 'S' && !purchase.has_assambly_calification && purchase.num_serv_armado) {
                // 
                this.setState({
                  openModalQualificationAssembly: true,
                  openModalQualificationSummary: true,
                  assemblyClf: {
                    ...this.state.assemblyClf,
                    ref: purchase.num_serv_armado,
                    op: purchase.consec_orden,
                    num_orden: purchase.num_serv_armado
                  }
                });
                valid = false;
              }
            }
          }
        }
        // PARAR INTERVAL.
        clearInterval(time);

      }
    }, 100);
  }
  /**
   * Cambia de página actual
   * @param {*} _page Número de página a cambiar
   */
  handleChangePage = (page) => {
    window.scrollRef.scrollTo({
      top: 20,
      behavior: "smooth"
    });
    this.setState({
      pagination: {
        ...this.state.pagination,
        page: page
      }
    });
  }

  /**
   * Obtener fechas de diagnostico por defecto
   */
  getDateDefault = () => {
    this.setState({ globalLoading: true });
    let productSelected = this.state.productSelected;
    let vcDepto = "";
    let vcMun = "";
    let vcBar = "";
    let vcZona = "";
    if (productSelected && productSelected.tipo_diagnostico === "FISICO") {
      if (this.state.newDirection.newDirectionFlag) {
        vcDepto = (this.state.selectDepartment.selectDepartmentSelect && this.state.selectDepartment.selectDepartmentSelect.codigo) ? this.state.selectDepartment.selectDepartmentSelect.codigo : "";
        vcMun = (this.state.selectCity.selectCitySelect && this.state.selectCity.selectCitySelect.codigo) ? this.state.selectCity.selectCitySelect.codigo : "";
        vcBar = (this.state.selectNeighborhood.selectNeighborhoodSelect && this.state.selectNeighborhood.selectNeighborhoodSelect.codigo) ? this.state.selectNeighborhood.selectNeighborhoodSelect.codigo : "";
        vcZona = (this.state.selectNeighborhood.selectNeighborhoodSelect && this.state.selectNeighborhood.selectNeighborhoodSelect.zonalog) ? this.state.selectNeighborhood.selectNeighborhoodSelect.zonalog : "";;
      } else {
        let direction = this.state.selectDirections.selectDirectionsSelect;
        vcDepto = direction.c_depto;
        vcMun = direction.c_mnpo;
        vcBar = direction.bar;
        vcZona = direction.zonalog;
      }
    }
    let params = {
      pe_vcEmp: this.state.userData.company,
      pe_vcNumOrd: productSelected.consec_orden,
      pe_vcAgeOrd: productSelected.age_orden,
      pe_vcPerOrd: productSelected.per_orden,
      pe_vcDepto: vcDepto,
      pe_vcMun: vcMun,
      pe_vcBar: vcBar,
      pe_vcZona: vcZona,
      pe_vcTipoDiag: productSelected.tipo_diagnostico
    }
    http.post(`${this.state.userData.company}/dates`, params).then(result => {
      if (result.data) {

        if (result.data.ps_vcCalendario) {
          let calendario = result.data.ps_vcCalendario;
          let datesCalendar = calendario.split(";");

          let dates = {};

          for (let i = 0; i < datesCalendar.length; i++) {
            let item = datesCalendar[i].split(":");
            let _date = item[0];
            let workingDay = item[1];
            if (dates[_date]) {
              dates[_date].push(workingDay);
            } else {
              dates[_date] = [];
              dates[_date].push(workingDay);
            }
          }

          let newDates = [];
          for (const key in dates) {
            if (dates.hasOwnProperty(key)) {
              let _workingDay = dates[key];
              let element = {
                date: key,
                workingDayArray: _workingDay,
                workingDaySelect: (_workingDay.length > 0) ? _workingDay[0] : ""
              };
              newDates.push(element);
            }
          }

          this.setState({
            dateDiagnostic: {
              dateDiagnosticArray: newDates
            },
            dateDiagnosticSelect: newDates[0],
            idTecnico: (result.data.ps_vcTec) ? result.data.ps_vcTec : "",
            globalLoading: false
          });
        } else {
          this.setState({
            idTecnico: (result.data.ps_vcTec) ? result.data.ps_vcTec : ""
          });
          if (this.state.productSelected && this.state.productSelected.tipo_diagnostico === "VIRTUAL") {
            this.changeCurrentPage("DESC_PROBLEM");
          } else {
            this.changeCurrentPage("INFO_BIOSECURITY")
          }
          this.setState({ globalLoading: false });
        }
      } else {
        this.setState({ globalLoading: false });
        this.changeCurrentPage("SET_DATE_DIAGNOSTIC");
      }
    }, () => {
      this.setState({ globalLoading: false });
    });
  }

  /**
   * Obtener fechas de diagnostico disponibles
   */
  getDateDiagnostic = () => {

  }

  /**
   * Obtener fechas de diagnostico disponibles
   */
  getDirections = () => {
    this.setState({ globalLoading: true });
    let urlBase = process.env.REACT_APP_JAMAR;
    let token = getCookie("token");
    let formData = {
      company: this.state.userData.company,
      nide: encryptData(this.state.userData.document)
    };
    http.post(`${urlBase}users/address`, formData, { headers: { 'Authorization': `Bearer ${token}` } }).then(result => {   //41
      let result_arr = convertKeysToLowercase(result.data);
      let result_arr_dec = processArrayAddress(result_arr, 'fecha_modificacion');
      if (result_arr_dec && result_arr_dec.length > 0) {
        result_arr_dec.unshift(this.state.productSelected.direccion);
        this.setState({
          selectDirections: {
            selectDirectionsArray: result_arr_dec,
            selectDirectionsSelect: result_arr_dec[1]
          }
        });
        this.setState({ globalLoading: false });
      } else {
        this.setState({ globalLoading: false });
        this.changeCurrentPage("NEW_DIRECTION");
      }
    }, () => {
      this.setState({ globalLoading: false });
    });
  }

  /**
   * Obtiene la lista de departamentos
   */
  getDepartment = () => {
    this.setState({ globalLoading: true });
    http.get(`${this.state.userData.company}/departments`).then(result => {
      if (result.data && result.data.length > 0) {
        let arrayDeparment = result.data;
        arrayDeparment.unshift({
          codigo: "-2",
          descripcion: "-- Seleccione --"
        });
        this.setState({
          selectDepartment: {
            selectDepartmentArray: arrayDeparment,
            selectDepartmentSelect: arrayDeparment[0]
          }
        });
      }
      this.setState({ globalLoading: false });
    }, () => {
      this.setState({ globalLoading: false });
    });
  }

  /**
   * Obtiene la lista de ciudades
   */
  getCities = (deparment) => {
    this.setState({
      selectCity: {
        selectCityArray: LOADING_COMBO,
        selectCitySelect: LOADING_COMBO[0]
      }
    });
    http.get(`${this.state.userData.company}/departments/${deparment.codigo}/cities`).then(result => {
      if (result.data && result.data.length > 0) {
        this.setState({
          selectCity: {
            selectCityArray: result.data,
            selectCitySelect: result.data[0]
          }
        });
      }
    }, (error) => {
      console.log(error)
    });
  }

  /**
   * Obtiene la lista de barrios
   */
  getNeighborhood = (city) => {
    this.setState({
      selectNeighborhood: {
        selectNeighborhoodArray: LOADING_COMBO,
        selectNeighborhoodSelect: LOADING_COMBO[0]
      }
    });
    let deparment = this.state.selectDepartment.selectDepartmentSelect;
    http.get(`${this.state.userData.company}/departments/${deparment.codigo}/cities/${city.codigo}/neigborhoods`).then(result => {
      if (result.data && result.data.length > 0) {
        this.setState({
          selectNeighborhood: {
            selectNeighborhoodArray: result.data,
            selectNeighborhoodSelect: result.data[0]
          }
        });
      }
    }, (error) => {
      console.log(error)
    });
  }

  /**
   * Actualizar la fecha de diagnostico
   * @param {*} event obj evento
   */
  handleChangeDateDiagnostic = (event) => {
    this.setState({
      dateDiagnosticSelect: (event.target.value) ? event.target.value : {}
    });
  }

  /**
   * Actualizar la jornada para el diagnostico
   * @param {*} event obj evento
   */
  handleChangeWorkingDayDiagnostic = (event) => {
    let _dateDiagnosticSelect = this.state.dateDiagnosticSelect;
    _dateDiagnosticSelect.workingDaySelect = (event.target.value) ? event.target.value : "";
    this.setState({
      dateDiagnosticSelect: _dateDiagnosticSelect
    });
  }

  /**
   * Actualizar la dirección para el diagnostico
   * @param {*} event obj evento
   */
  handleChangeSelectDirections = (event) => {
    this.setState({
      selectDirections: {
        ...this.state.selectDirections,
        selectDirectionsSelect: (event.target.value) ? event.target.value : ""
      }
    });
  }

  /**
   * Actualizar el indicador de dirección para el diagnostico
   * @param {*} event obj evento
   */
  handleChangeSelectIndexDirection = (event) => {
    this.setState({
      selectIndexDirection: {
        ...this.state.selectIndexDirection,
        selectIndexDirectionSelect: (event.target.value) ? event.target.value : ""
      }
    });
  }

  /**
   * Actualizar el interior1
   * @param {*} event obj evento
   */
  handleChangeSelectInterior1 = (event) => {
    this.setState({
      selectInterior1: {
        ...this.state.selectInterior1,
        selectInterior1Select: (event.target.value) ? event.target.value : ""
      }
    });
  }

  /**
   * Actualizar el interior2
   * @param {*} event obj evento
   */
  handleChangeSelectInterior2 = (event) => {
    this.setState({
      selectInterior2: {
        ...this.state.selectInterior2,
        selectInterior2Select: (event.target.value) ? event.target.value : ""
      }
    });
  }

  /**
   * Actualizar inputs de texto para nueva la descripción del problema
   * @param {*} event obj evento
   */
  changeHandlerProblemDescription = (event) => {
    this.setState({
      problemDescription: {
        ...this.state.problemDescription,
        [event.target.name]: (event.target.value) ? event.target.value : ""
      }
    });
  }

  /**
   * Actualizar inputs de texto para nueva dirección
   * @param {*} event obj evento
   */
  changeHandlerNewDirection = (event) => {
    this.setState({
      newDirection: {
        ...this.state.newDirection,
        [event.target.name]: (event.target.value) ? event.target.value : ""
      }
    });
  }

  /**
   * Actualizar el departamento de la nueva dirección
   * @param {*} event obj evento
   */
  handleChangeSelectDepartment = (event) => {
    this.setState({
      selectDepartment: {
        ...this.state.selectDepartment,
        selectDepartmentSelect: (event.target.value) ? event.target.value : ""
      }
    });
    if (event.target.value && event.target.value.codigo !== "-2" && event.target.value.codigo !== "-1") {
      this.getCities((event.target.value) ? event.target.value : "");
    } else {
      this.setState({
        selectCity: {
          selectCityArray: [],
          selectCitySelect: {}
        },
        selectNeighborhood: {
          selectNeighborhoodArray: [],
          selectNeighborhoodSelect: {}
        },
      });
    }
  }

  /**
   * Actualizar la ciudad de la nueva dirección
   * @param {*} event obj evento
   */
  handleChangeSelectCity = (event) => {
    this.setState({
      selectCity: {
        ...this.state.selectCity,
        selectCitySelect: (event.target.value) ? event.target.value : ""
      }
    });
    this.getNeighborhood((event.target.value) ? event.target.value : "");
  }

  /**
   * Actualizar el barrio de la nueva dirección
   * @param {*} event obj evento
   */
  handleChangeSelectNeighborhood = (event) => {
    this.setState({
      selectNeighborhood: {
        ...this.state.selectNeighborhood,
        selectNeighborhoodSelect: (event.target.value) ? event.target.value : ""
      }
    });
  }

  /**
   * Actualizar Checkbox Terminos y Condiciones
   * @param {*} event obj evento
   */
  handleChangeCheckTermsAndConditions = (event) => {
    this.setState({
      checkboxAcceptTermsAndConditions: event.target.checked
    });
  }

  /**
   * Actualizar Checkbox Terminos y Condiciones
   * @param {*} event obj evento
   */
  handleChangeCheckTermsTracking = (event) => {
    this.setState({
      checkboxAcceptTermsTracking: event.target.checked
    });
  }
  /**
   * Actualizar Checkbox Terminos y Condiciones
   * @param {*} event obj evento
   */
  handleChangeCheckTermsAssembly = (event) => {
    this.setState({
      checkboxAcceptTermsAssembly: event.target.checked
    });
  }

  /**
   * Filtra la lista de productos por nombre (buscador)
   * @param {*} _filterText Texto a filtrar
   */
  setFilterText = (_filterText) => {
    let _productList = this.state.productList;
    _productList = _productList.filter(_productList => _productList.nom_producto.toLowerCase().indexOf(_filterText.toLowerCase()) > -1);
    this.setState({ productListToShow: _productList });
  }

  /**
   * Filtra la lista de productos por los checkbox (estado de garantia)
   */
  setFilterCheckboxs = (_filterInProcess, _filterAvailable, _filterNoAvailable) => {
    let _productListIN_PROCESS = this.state.productList;
    let _productListAVAILABLE = this.state.productList;
    let _productListNO_AVAILABLE = this.state.productList;

    _productListIN_PROCESS = _productListIN_PROCESS.filter(product => product.aplica_garantia && product.estado_garantia);
    _productListAVAILABLE = _productListAVAILABLE.filter(product => product.aplica_garantia && !product.estado_garantia);
    _productListNO_AVAILABLE = _productListNO_AVAILABLE.filter(product => !product.aplica_garantia);

    let NEW_PRODUCT_LIST = [];

    if (_filterInProcess) {
      NEW_PRODUCT_LIST = NEW_PRODUCT_LIST.concat(_productListIN_PROCESS);
    }
    if (_filterAvailable) {
      NEW_PRODUCT_LIST = NEW_PRODUCT_LIST.concat(_productListAVAILABLE);
    }
    if (_filterNoAvailable) {
      NEW_PRODUCT_LIST = NEW_PRODUCT_LIST.concat(_productListNO_AVAILABLE);
    }
    this.setState({ productListToShow: NEW_PRODUCT_LIST });
  }

  /**
   * Al continuar los terminos y condiciones...
   */
  continueTermsAndConditions = () => {
    if (this.state.checkboxAcceptTermsAndConditions) {
      this.setState({ showAlertTermsAndConditions: false });
      if (this.state.productSelected && this.state.productSelected.tipo_diagnostico === "VIRTUAL") {
        if (this.state.productSelected.sfConsulta.disponible) {
          this.changeCurrentPage("DESC_PROBLEM");
        } else {
          this.changeCurrentPage("DATE_DEFAULT");
        }
      } else {
        this.changeCurrentPage("DESC_PROBLEM_FISICO");
      }
    } else {
      this.setState({ showAlertTermsAndConditions: true });
    }
  }
  /**
   * Al continuar los terminos y condiciones...
   */
  continueTermsTracking = () => {
    if (this.state.checkboxAcceptTermsTracking) {
      this.setState({ showAlertTermsTracking: false });
      this.props.activeMenuEntrega(false);
      this.props.history.push({
        pathname: '/jamarAuth/delivery',
        state: {
          purchase: this.state.orderSelected
        }
      });
    } else {
      this.setState({ showAlertTermsTracking: true });
    }
  }
  /**
   * Al continuar los terminos y condiciones...
   */
  continueTermsAssembly = () => {
    if (this.state.checkboxAcceptTermsAssembly) {
      this.setState({ showAlertTermsAssembly: false });
      this.props.history.push({
        pathname: '/jamarAuth/assembly',
        state: {
          purchase: this.state.orderSelected
        }
      });
    } else {
      this.setState({ showAlertTermsAssembly: true });
    }
  }
  /**
   * Al continuar agendar armado...
   */
  continueAssembly = (purchase) => {
    // VALIDAR ESTADO.
    if (purchase.estado_armado === 'PENDIENTE') {
      this.props.history.push({
        pathname: '/jamarAuth/assembly',
        state: {
          purchase: purchase
        }
      });
    } else {
      // 
      this.setState({ orderSelected: purchase });
      // 
      this.changeCurrentPage("TERMS_CONDITIONS_ASSEMBLY");
    }
  }

  /**
   * Valida si se muestra la lista con los filtros aplicados
   */
  validateUseFilters = () => {
    let filterInProcess = this.state.filtersWarranty.filterInProcess;
    let filterAvailable = this.state.filtersWarranty.filterAvailable;
    let filterNoAvailable = this.state.filtersWarranty.filterNoAvailable;
    if (filterInProcess && filterAvailable && filterNoAvailable) {
      return false;
    } else if (!filterInProcess && filterAvailable && filterNoAvailable) {
      return true;
    } else if (!filterInProcess && !filterAvailable && filterNoAvailable) {
      return true;
    } else if (!filterInProcess && filterAvailable && !filterNoAvailable) {
      return true;
    } else if (filterInProcess && !filterAvailable && filterNoAvailable) {
      return true;
    } else if (!filterInProcess && !filterAvailable && filterNoAvailable) {
      return true;
    } else if (filterInProcess && !filterAvailable && !filterNoAvailable) {
      return true;
    } else if (filterInProcess && filterAvailable && !filterNoAvailable) {
      return true;
    } else if (!filterInProcess && filterAvailable && !filterNoAvailable) {
      return true;
    } else if (filterInProcess && !filterAvailable && !filterNoAvailable) {
      return true;
    } else if (!filterInProcess && !filterAvailable && !filterNoAvailable) {
      return true;
    }
  }

  /**
   * MÉTODO PARA ABRIR EXPLORADOR AL DAR CLICK BOTÓN SUBIR ARCHIVO.
   * @param {*} event 
   */
  handleClick = (event) => {
    document.getElementById(event).click();
  };
  /**
   * MÉTODO PARA GUARDAR IMAGEN EN BASE64 EN EL CAMPO CORRESPONDIENTE.
   * @param {*} event 
   */
  handleChange = async (event) => {
    // 
    const fileUploaded = event.target.files[0];
    const name = event.target.name;
    
    await getBase64(fileUploaded, (result) => {
      // 
      this.setState({
        photos: {
          ...this.state.photos,
          [name]: {
            file: result,
            name: fileUploaded.name
          }
        }
      });
    });
  };
  /**
   * 
   * @param {*} event 
   */
  setDataPhoto = (event) => {
    event.preventDefault();
    let valid = true;
    // VALIDAR SI TODOS LOS CAMPOS ESTA LLENOS.
    for (const property in this.state.photos) {
      if (!this.state.photos[property].file) valid = false;
    }
    // 
    if (valid) {
      this.changeCurrentPage("SELECT_DIRECTIONS", true);
    } else {
      // ALERT - CAMPOS REQUERIDOS.
      toast.warn('Por favor, debe llenar todos los campos.', {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: true,
        progress: undefined,
      });
    }
  }

  /**
   * Cerrar modal de mostrar motivo de no cumplimiento
   * @param {*} cancel 
   */
  handleCloseModalMotNoCumplimiento = () => {
    this.selectProduct({});
    this.setState({
      openModalShowMotNoCumplimiento: false,
    });
  }

  /**
   * Mostrar el motivo_no_cumplimiento para que el usuario
   * sepa porque no aplica la garantía para el producto seleccionado
   * @param {*} product 
   */
  handleOpenModalMotNoCumplimiento(product) {
    this.selectProduct(product);
    this.setState({
      openModalShowMotNoCumplimiento: true
    });
  }

  /**
   * Cerrar modal de calificación
   * @param {*} cancel 
   */
  handleCloseModalQualification = (cancel) => {
    this.setState({
      openModalQualification: false,
      productClf: {
        ...this.state.productClf,
        ref: null,
        op: null,
        value: null,
        observation: null
      },
      productSelected: {}
    });
    if (cancel !== "cancel") { }
  }
  /**
   * Cerrar modal de calificación
   * @param {*} cancel 
   */
  handleCloseModalQualificationDelivery = (cancel) => {
    this.setState({
      openModalQualificationDelivery: false,
      deliveryClf: {
        ...this.state.deliveryClf,
        ref: null,
        op: null,
        value: null,
        observation: null
      }
    });
    localStorage.setItem("qualificationModalAlreadySeen", true);
    if (cancel !== "cancel") { }
  }
  /**
   * Cerrar modal de calificación
   * @param {*} cancel 
   */
  handleCloseModalQualificationAssembly = (cancel) => {
    this.setState({
      openModalQualificationAssembly: false,
      assemblyClf: {
        ...this.state.assemblyClf,
        ref: null,
        op: null,
        num_orden: null,
        value: null,
        observation: null
      }
    });
    localStorage.setItem("deliveryModalAlreadySeen", true);
    if (cancel !== "cancel") { }
  }

  handleCloseModalQualificationSummary = (cancel) => {
    this.setState({
      openModalQualificationSummary: false,
      assemblyClf: {
        ...this.state.assemblyClf,
        ref: null,
        op: null,
        num_orden: null,
        value: null,
        observation: null
      },
      deliveryClf: {
        ...this.state.deliveryClf,
        ref: null,
        op: null,
        value: null,
        observation: null
      }
    });
    localStorage.setItem("deliveryModalAlreadySeen", true);
    localStorage.setItem("qualificationModalAlreadySeen", true);
    if (cancel !== "cancel") { }
  }

  /**
   * Mostrar modal de calificación
   * @param {*} product 
   * @param {*} consec_orden 
   */
  handleOpenModalQualification(product, consec_orden) {
    this.setState({
      openModalQualification: true,
      productClf: {
        ...this.state.productClf,
        ref: product.cod_producto,
        op: consec_orden
      },
      productSelected: product
    });
  }

  /**
   * Enviar calificación de producto
   */
  sendQualification = (event) => {
    event.preventDefault();
    // 
    this.props.productQualificationResult({
      showAlert: false,
      message: null,
      loader: false
    });
    // DATOS DE LA ENTREGA
    const data = this.state.productClf;
    // 
    if (!data.value) {
      // 
      this.props.productQualificationResult({
        showAlert: true,
        message: "Debe colocar una calificación",
        loader: false
      });
    } else {
      // 
      this.props.productQualification({
        company: this.props.userData.company,
        op: data.op,
        params: {
          value: data.value,
          observation: data.observation,
          type: "P",
          ref: data.ref
        },
        purchases: this.props.purchasesData,
        closeModalQualification: this.handleCloseModalQualification
      });
    }
  }
  /**
   * MÉTODO GUARDAR LOS CAMBIO DE LOS CAMPOS DEL FORMULARIO.
   * @param {*} event 
   */
  handleChangeDateDelivery = event => {
    this.setState({
      newFechajornada: {
        ...this.state.newFechajornada,
        [event.target.name]: event.target.value
      }
    });
  }

  handlePhoneChange = (e) => {
    const phone = e.target.value.replace(/[^\d]/g, '')
    this.setState({
      userPhone: phone
    })
  }

  handleMailChange = (e) => {
    const mail = e.target.value.replace(/[^\d]/g, '')
    this.setState({
      userMail: e.target.value
    })
  }

  /**
   * Enviar calificación de producto
   */
  sendQualificationDelivery = (event) => {    
    event.preventDefault();
    // 
    this.props.deliveryQualificationResult({
      showAlert: false,
      message: null,
      loader: false
    });
    // DATOS DE LA ENTREGA
    const data = this.state.deliveryClf;
    // 
    if (!data.value) {
      // 
      this.props.deliveryQualificationResult({
        showAlert: true,
        message: "Debe colocar una calificación",
        loader: false
      });
    } else {
      // 
      this.props.deliveryQualification({
        company: this.props.userData.company,
        op: data.op,
        params: {
          value: data.value,
          observation: data.observation,
          type: "E",
          ref: data.ref
        },
        purchases: this.props.purchasesData,
        closeModalQualification: this.handleCloseModalQualificationDelivery
      });
    }
  }
  /**
   * Enviar calificación de producto
   */
  sendQualificationAssembly = (event) => {
    event.preventDefault();
    // 
    this.props.assemblyQualificationResult({
      showAlert: false,
      message: null,
      loader: false
    });
    // DATOS DE LA ENTREGA
    const data = this.state.assemblyClf;
    // 
    if (!data.value) {
      // 
      this.props.assemblyQualificationResult({
        showAlert: true,
        message: "Debe colocar una calificación",
        loader: false
      });
    } else {
      // 
      this.props.assemblyQualification({
        company: this.props.userData.company,
        op: data.op,
        params: {
          value: data.value,
          observation: data.observation,
          type: "A",
          ref: data.ref
        },
        purchases: this.props.purchasesData,
        closeModalQualification: this.handleCloseModalQualificationAssembly
      });
    }
  }

  sendQualificationSummary = (event) => {    
    event.preventDefault();
    // DATOS DEL ARMADO
    const dataAssembly = this.state.assemblyClf;
    // 
    // DATOS DE LA ENTREGA
    const dataDelivery = this.state.deliveryClf;
    //
    if (this.state.assemblyClf.value) {
      this.sendQualificationAssembly(event);
    }
    if (this.state.deliveryClf.value) {
      this.sendQualificationDelivery(event);
    }
  }

  submitVirtualWarranty = (e) => {
    e.preventDefault();
    this.sendSaveService(true);
  }

  /**
   * Petición para guardar la información
   */
  sendSaveService = (virtual = false) => {
    // SHOW LONDING.
    this.props.showLoader();
    this.setState({ virtual: false });
    // 
    let productSelected = this.state.productSelected;
    let PHOTOS = this.state.photos;
    let BASE64_PHOTOS = [];
    if (productSelected && productSelected.tipo_diagnostico === "VIRTUAL") {

      BASE64_PHOTOS = [
        PHOTOS.photo_01.file,
        PHOTOS.photo_02.file,
        PHOTOS.photo_03.file,
        PHOTOS.photo_04.file,
        PHOTOS.photo_05.file,
      ];
    }
    let vcDepto = "";
    let vcMun = "";
    let vcBar = "";
    let vcZona = "";
    let vcDir = "";
    let vcPais = "";
    if (productSelected && productSelected.tipo_diagnostico === "FISICO") {
      if (this.state.newDirection.newDirectionFlag) {
        vcDepto = (this.state.selectDepartment.selectDepartmentSelect && this.state.selectDepartment.selectDepartmentSelect.codigo) ? this.state.selectDepartment.selectDepartmentSelect.codigo : "";
        vcMun = (this.state.selectCity.selectCitySelect && this.state.selectCity.selectCitySelect.codigo) ? this.state.selectCity.selectCitySelect.codigo : "";
        vcBar = (this.state.selectNeighborhood.selectNeighborhoodSelect && this.state.selectNeighborhood.selectNeighborhoodSelect.codigo) ? this.state.selectNeighborhood.selectNeighborhoodSelect.codigo : "";
        vcZona = (this.state.selectNeighborhood.selectNeighborhoodSelect && this.state.selectNeighborhood.selectNeighborhoodSelect.zonalog) ? this.state.selectNeighborhood.selectNeighborhoodSelect.zonalog : "";;
        vcDir = this.state.DIRECTION_TO_RETURN;
        vcPais = (this.state.selectDepartment.selectDepartmentSelect && this.state.selectDepartment.selectDepartmentSelect.c_pai) ? this.state.selectDepartment.selectDepartmentSelect.c_pai : "";
      } else {
        let direction = this.state.selectDirections.selectDirectionsSelect;
        vcDepto = direction.c_depto;
        vcMun = direction.c_mnpo;
        vcBar = direction.bar;
        vcZona = direction.zonalog;
        vcDir = direction.dir;
        vcPais = direction.c_pais;
      }
    }
    let _OBS = "";
    let _PRODUCT_DESC = `PRODUCTO: ${productSelected.cod_producto} ${productSelected.nom_producto}`;
    if (!productSelected.sfConsulta.disponible && !virtual) {
      _OBS = _PRODUCT_DESC;
    }
    _OBS += " QUE TIENE EL PRODUCTO: " + this.state.problemDescription.queTieneElProducto + " ";
    _OBS += " EN QUE PARTE: " + this.state.problemDescription.enQueParte + " ";
    _OBS += " DONDE SE ENCUENTRA: " + this.state.problemDescription.dondeSeEncuentra + " ";
    let _TEL, _EMAIL;
    if (virtual) {
      const direction = this.state.selectDirections.selectDirectionsSelect;
      const fullDirection = `${direction.dir}  |  ${direction.nom_depto}/${direction.nom_mnpo} - ${direction.nom_bar}`;
      _OBS += " DIRECCION: ";
      _OBS += this.state.DIRECTION_TO_RETURN !== "" ? this.state.DIRECTION_TO_RETURN : fullDirection + " ";
      // Dirección para enviar al account
      vcDepto = this.state.selectDepartment.selectDepartmentSelect.descripcion ? this.state.selectDepartment.selectDepartmentSelect.descripcion : direction.nom_depto;
      vcMun = this.state.selectCity.selectCitySelect.descripcion ? this.state.selectCity.selectCitySelect.descripcion : direction.nom_mnpo;
      vcDir = this.state.DIRECTION_TO_RETURN !== "" ? this.state.DIRECTION_TO_RETURN : direction.dir;
      if (productSelected.sfConsulta.disponible) {
        _EMAIL = this.state.userMail;
        _TEL = this.state.userPhone;
      } else {
        _OBS += " TELEFONO: " + this.state.userPhone + " ";
        _OBS += " CORREO ELECTRONICO: " + this.state.userMail + " ";
      }
    }

    let params = {
      pe_vcEmp: this.state.userData.company,
      pe_vcNumOrd: productSelected.consec_orden,
      pe_vcAgeOrd: productSelected.age_orden,
      pe_vcPerOrd: productSelected.per_orden,
      pe_vcDepto: vcDepto,
      pe_vcMun: vcMun,
      pe_vcBar: vcBar,
      pe_vcZonaLog: vcZona,
      pe_vcPais: vcPais,
      pe_vcDir: vcDir,
      pe_vcNIde: this.state.userData.document,
      pe_vcCodProd: productSelected.cod_producto,
      pe_vcCanProd: (productSelected.cantidad !== null) ? productSelected.cantidad.toString() : "",
      pe_vcAplGarantia: productSelected.aplica_garantia,
      pe_vcTipoGar: productSelected.tipo_garantia,
      pe_vcTipoDiag: productSelected.tipo_diagnostico,
      pe_vcMotNoAplica: productSelected.motivo_no_cumplimiento,
      pe_vcNumFac: "",
      pe_vcRegistraPQR: "S",
      pe_vcCausalPQR: "",
      pe_vcObs: _PRODUCT_DESC,
      pe_vcFechaAgenda: (this.state.dateDiagnosticSelect && this.state.dateDiagnosticSelect.date) ? this.state.dateDiagnosticSelect.date : "",
      pe_vcJornadaAgenda: (this.state.dateDiagnosticSelect && this.state.dateDiagnosticSelect.workingDaySelect) ? this.state.dateDiagnosticSelect.workingDaySelect : "",
      pe_vcSenas: this.state.newDirection.senia,
      pe_vcTecnico: this.state.idTecnico,
      pe_vcGen_Des: "",
      pe_vcPrioritario: "",
      pe_vcRes_Pag: "",
      pe_vcPago: "",
      pe_vcDev: "",
      pe_vcAju_Car: "",
      pe_vcObsServTec: _OBS,
      pe_vcProceso: "PORTAL_CLIENTE",
      pe_vcUsuario: "",
      fotos: BASE64_PHOTOS
    }
    if (productSelected.sfConsulta.disponible && virtual) {
      params.tel = _TEL;
      params.email = _EMAIL;
      params.pe_vcTipoGar = this.state.tipoGarantiaSf;
    }
    let saveWarrantyUrl = process.env.REACT_APP_JAMAR;
    let token = getCookie("token");
    
    http.post(`${saveWarrantyUrl}saveWarranty`, params, { headers : { 'Authorization': `Bearer ${token}`} }).then(result => {
      if (result.data) {
        if (productSelected.sfConsulta.disponible) {
          if (result.data.status === "Ok") {
            this.setState({
              NUM_SERVICE: (result.data.numeroCaso) ? result.data.numeroCaso : ""
            });
            // LONDING.
            this.props.showLoader();
            // EJECUTAR SERVICIO PARA OBTENER LAS COMPRAS.
            this.props.setpurchasesData(this.props.userData);
            // 
            this.changeCurrentPage("GRATULATIONS");
          } else {
            // ABRIR MODAL WHATSAPP
            this.setState({ openModalWhatsappWarning: true });
            // LONDING.
            this.props.showLoader();
            // EJECUTAR SERVICIO PARA OBTENER LAS COMPRAS.
            this.props.setpurchasesData(this.props.userData);
          }
        } else {
          if (result.data.ps_vcNumServ) {
            this.setState({
              NUM_SERVICE: (result.data.ps_vcNumServ) ? result.data.ps_vcNumServ : ""
            });
            // LONDING.
            this.props.showLoader();
            // EJECUTAR SERVICIO PARA OBTENER LAS COMPRAS.
            this.props.setpurchasesData(this.props.userData);
            // 
            this.changeCurrentPage("GRATULATIONS");
            // if (!result.data.ps_vcNumServTec) {
            //   // ABRIR MODAL WHATSAPP
            //   this.setState({ openModalWhatsappWarning: true, ps_vcNumServ: result.data.ps_vcNumServ, ps_vcDescripcion: _OBS });
            //   // HIDE LONDING.
            //   this.props.hideLoader();
            // }
          } else {
            // ABRIR MODAL WHATSAPP
            this.setState({ openModalWhatsappWarning: true });
            // LONDING.
            this.props.showLoader();
            // EJECUTAR SERVICIO PARA OBTENER LAS COMPRAS.
            this.props.setpurchasesData(this.props.userData);
          }
        }
      } else {
        // ALERT - CAMPOS REQUERIDOS.
        this.setState({ openModalWhatsappWarning: true });
        // LONDING.
        this.props.showLoader();
        // EJECUTAR SERVICIO PARA OBTENER LAS COMPRAS.
        this.props.setpurchasesData(this.props.userData);
      }
    }, () => {
      // ALERT - CAMPOS REQUERIDOS.
      this.setState({ openModalWhatsappWarning: true });
      // HIDE LONDING.
      this.props.hideLoader();
    });

  }
  /**
   * MÉTODO PARA FORMATEAR FECHA
   * @param {*} date 
   */
  dateformat(date) {
    return moment(date).format('DD/MM/YYYY');
  }
  /**
   * Cerrar modal de whatsapp
   * @param {*} cancel 
   */
  handleCloseModalWhatsappWarning = (cancel) => {
    this.setState({
      openModalWhatsappWarning: false
    });
    this.changeCurrentPage("PRODUCT_LIST");
    if (cancel !== "cancel") { }
  }

  nl2br(str, is_xhtml) {
    if (typeof str === 'undefined' || str === null) {
      return '';
    }
    let breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? ' <br/>' : ' <br>';
    let result = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2');
    let regex = /&/g,
      match,
      insert = false,
      texto = result;
    //bucle para todas las coincidencias
    while ((match = regex.exec(texto)) !== null) {
      if (insert) {
        insert = false;
        result = result.replace(match[0], `</b>`);

      } else {
        insert = true;
        result = result.replace(match[0], `<b>`);
      }
    }
    // 
    result = result.replace(`$1$`, `<span class="item-num">1</span>`);
    result = result.replace(`$1$`, `<span class="item-num">1</span>`);
    result = result.replace(`$2$`, `<span class="item-num">2</span>`);
    result = result.replace(`$2$`, `<span class="item-num">2</span>`);
    result = result.replace(`$3$`, `<span class="item-num">3</span>`);
    result = result.replace(`$3$`, `<span class="item-num">3</span>`);
    result = result.replace(`$4$`, `<span class="item-num">4</span>`);
    result = result.replace(`$4$`, `<span class="item-num">4</span>`);
    result = result.replace(`$5$`, `<span class="item-num">5</span>`);
    result = result.replace(`$5$`, `<span class="item-num">5</span>`);


    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');
    result = result.replace(`{*}`, '<i class="fa fa-circle" style="color: #c52632; font-size: 10px;"></i>');

    return result;
  }
  /**
   * 
   */
  inactiveMenuEntrega() {
    this.props.activeMenuEntrega(false);
  }

  getStatus(estado) {
    switch (estado) {
      case "GENERADA":
        return { icon: GeneradaIcon, message: "Orden Generada" };
      case "CONFIRMADA":
        return { icon: ConfirmadaIcon, message: "Orden Confirmada" };
      case "ALISTADA":
        return { icon: AlistamientoIcon, message: "Orden Alistamiento" };
      case "ALISTAMIENTO":
        return { icon: AlistamientoIcon, message: "Orden Alistamiento" };
      case "RUTA":
        return { icon: RutaIcon, message: "Orden en Ruta" };
      case "ENTREGADA":
        return { icon: EntregadaIcon, message: "Orden Entregada" };
      default:
        return { icon: GeneradaIcon, message: "Orden Generada" };
    }
  }

  /**
   * Determina que pagina mostrar
   */
  renderView() {

    // Logica para mostrar la lista de productos con paginación
    const indexOfLastTodo = this.state.pagination.page * this.state.pagination.pageSize;
    const indexOfFirstTodo = indexOfLastTodo - this.state.pagination.pageSize;
    // let _productList = this.state.productList.slice(indexOfFirstTodo, indexOfLastTodo);
    let _productList = this.props.purchasesData.slice(indexOfFirstTodo, indexOfLastTodo);
    const totalNumberOfPurchases = this.props.purchasesData.length;
    if (this.state.filterText || this.validateUseFilters()) {
      // _productList = this.state.productListToShow;
      _productList = this.state.purchasesListToShow;
    }

    // Stylos Modal
    const stylesModal = {
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      },
      paper: {
        backgroundColor: "#fff",
        boxShadow: "0px 3px 6px #0003",
        padding: "16px 20px 24px",
        borderRadius: 19
      },
      description: {
        textAlign: "center",
        marginBottom: "1px"
      },
      title: {
        textAlign: "center",
        fontSize: "20px",
        fontWeight: 500
      }
    };

    const MODAL_SHOW_MOTIVO_NO_CUMPLIMIENTO = <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={stylesModal.modal}
      open={this.state.openModalShowMotNoCumplimiento}
      onClose={this.handleCloseModalMotNoCumplimiento}
      closeAfterTransition
      className="modal-terminos-condiciones"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={this.state.openModalShowMotNoCumplimiento}>
        <div style={stylesModal.paper}>
          <div className="row">
            <div className="col-12">
              <p id="transition-modal-description" style={stylesModal.description}>{this.state.productSelected.nom_producto}</p>
            </div>
            <div className="col-12 center-horizontal mt-3 mb-3">
              {(this.state.productSelected.motivo_no_cumplimiento) ? this.state.productSelected.motivo_no_cumplimiento : "NO APLICA"}
            </div>
            <div className="col-12 btn-content">
              {
                this.props.userData.company === 'JP' ?


                  <Button type="button" className={"btn btn-save"} variant="outlined" onClick={() => this.handleCloseModalMotNoCumplimiento()}>Aceptar</Button>

                  :
                  <div>
                    <Button type="button" className={"btn btn-cancel"} variant="outlined" onClick={() => this.handleCloseModalMotNoCumplimiento()}>Cancelar</Button>
                    <Button type="button" className={"btn btn-save"} variant="outlined" onClick={() => { this.goToChat(); this.handleCloseModalMotNoCumplimiento() }}>Ir al chat</Button>
                  </div>
              }

            </div>
          </div>
        </div>
      </Fade>
    </Modal>;

    // MODAL CALIFICACIÓN ENTREGA.
    const MODAL_CALIFICATION_DELIVERY =
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={stylesModal.modal}
        open={this.state.openModalQualificationDelivery}
        onClose={this.handleCloseModalQualificationDelivery}
        closeAfterTransition
        className="modal-rate-product"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={this.state.openModalQualificationDelivery}>
          <div style={stylesModal.paper}>
            <div className="row">
              <form onSubmit={this.sendQualificationDelivery}>
                <div className="col-12">
                  <p id="transition-modal-description" style={stylesModal.description}>Cómo calificas tu experiencia con:</p>
                  <p id="transition-modal-title" style={stylesModal.title}>Nuestro servicio de entrega {this.state.deliveryClf.op}</p>
                </div>
                {this.props.lvrQualResult.showAlert && <div className="col-6 offset-3" style={{ marginBottom: "15px" }}><Alert severity="error">{this.props.lvrQualResult.message}</Alert></div>}
                <div className="col-12 center-horizontal" style={{ marginBottom: "10px" }}><img style={{ borderRadius: "20px" }} width={150} height={150} alt={imgCalServ} src={imgCalServ} /></div>
                <div className="col-12 center-horizontal">
                  <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating
                      name="calification-delivery"
                      size="large"
                      value={this.state.deliveryClf.value}
                      onChange={(event, newValue) => {
                        this.setState({
                          deliveryClf: {
                            ...this.state.deliveryClf,
                            value: newValue
                          }
                        });
                      }}
                    />
                  </Box>
                </div>
                <div className="col-sm-8 col-12 v-center">
                  <TextField
                    style={{ width: "100%" }}
                    id="filled-multiline-static"
                    label="Comentario"
                    multiline
                    rows={4}
                    defaultValue={""}
                    variant="filled"
                    onChange={(event) => {
                      this.setState({
                        deliveryClf: {
                          ...this.state.deliveryClf,
                          observation: event.target.value
                        }
                      });
                    }}
                  />
                </div>
                <div className="col-12 btn-content mt-2">
                  <Button type="button" className={"btn btn-cancel"} variant="outlined" onClick={() => this.handleCloseModalQualificationDelivery("cancel")}>Cancelar</Button>
                  <BtnRed title="Enviar" isLoggedIn={this.props.lvrQualResult.loader}></BtnRed>
                </div>
              </form>
            </div>
          </div>
        </Fade>
      </Modal>

    // MODAL CALIFICACIÓN ARMADO.
    const MODAL_CALIFICATION_ASSEMBLY =
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={stylesModal.modal}
        open={this.state.openModalQualificationAssembly}
        onClose={this.handleCloseModalQualificationAssembly}
        closeAfterTransition
        className="modal-rate-product"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={this.state.openModalQualificationAssembly}>
          <div style={stylesModal.paper}>
            <form onSubmit={this.sendQualificationAssembly}>
              <div className="row">
                <div className="col-12">
                  <p id="transition-modal-description" style={stylesModal.description}>Cómo calificas tu experiencia con:</p>
                  <p id="transition-modal-title" style={stylesModal.title}>Nuestro servicio de armado {this.state.assemblyClf.num_orden}</p>
                </div>
                {this.props.asbQualResult.showAlert && <div className="col-6 offset-3" style={{ marginBottom: "15px" }}><Alert severity="error">{this.props.asbQualResult.message}</Alert></div>}
                <div className="col-12 center-horizontal" style={{ marginBottom: "10px" }}><img style={{ borderRadius: "20px" }} width={200} height={200} alt={imgCalArmado} src={imgCalArmado} /></div>
                <div className="col-12 center-horizontal">
                  <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating
                      name="calification-assembly"
                      size="large"
                      value={this.state.assemblyClf.value}
                      onChange={(event, newValue) => {
                        this.setState({
                          assemblyClf: {
                            ...this.state.assemblyClf,
                            value: newValue
                          }
                        });
                      }}
                    />
                  </Box>
                </div>
                <div className="col-sm-8 col-12 v-center">
                  <TextField
                    style={{ width: "100%" }}
                    id="filled-multiline-static"
                    label="Comentario"
                    multiline
                    rows={4}
                    defaultValue={""}
                    variant="filled"
                    onChange={(event) => {
                      this.setState({
                        assemblyClf: {
                          ...this.state.assemblyClf,
                          observation: event.target.value
                        }
                      });
                    }}
                  />
                </div>
                <div className="col-12 btn-content mt-2">
                  <Button type="button" className={"btn btn-cancel"} variant="outlined" onClick={() => this.handleCloseModalQualificationAssembly("cancel")}>Cancelar</Button>
                  <BtnRed title="Enviar" isLoggedIn={this.props.asbQualResult.loader}></BtnRed>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>

    function scrollToTargetAdjusted(element) {
      var headerOffset = 20;
      var elementPosition = Math.abs(element.getBoundingClientRect().top);
      var offsetPosition = elementPosition - headerOffset;

      window.scrollRef.scrollTo({
        top: offsetPosition,
        behavior: "smooth"
      });
    }
    const MODAL_CALIFICATION_SUMMARY = <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      style={stylesModal.modal}
      open={this.state.openModalQualificationSummary}
      onClose={this.handleCloseModalQualificationSummary}
      closeAfterTransition
      className="modal-rate-product"
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      {/* <Fade in={this.state.openModalQualificationDelivery || this.state.openModalQualificationAssembly}> */}
      <div>
        <div style={stylesModal.paper}>
          <div className="row">
            <form onSubmit={this.sendQualificationSummary}>
              <div className="col-12">
                <p id="transition-modal-description" style={stylesModal.description}>Cómo calificas tu experiencia con:</p>
                <p id="transition-modal-title" style={stylesModal.title}>Nuestros servicios de entrega y armado {this.state.deliveryClf.op}</p>
              </div>
              {this.state.openModalQualificationDelivery ? <div>{this.props.lvrQualResult.showAlert && <div className="col-6 offset-3" style={{ marginBottom: "15px" }}><Alert severity="error">{this.props.lvrQualResult.message}</Alert></div>}
                <div className="d-flex justify-content-start" style={{ borderTop: "2px dotted", paddingTop: "20px", borderTopColor: "#c6c6c6" }}>
                  <div className="" style={{ marginTop: "30px", marginLeft: "10px", marginRight: "10px" }}><img style={{ borderRadius: "20px" }} width={100} height={100} alt={imgCalServ} src={imgCalServ} /></div>
                  <div className="mx-auto" style={{ width: "80%" }}>
                    <div className="d-flex align-items-center flex-column" style={{ marginBottom: "10px", marginRight: "10px" }}>
                      <div className="center-horizontal">
                        <Box component="fieldset" mb={0} borderColor="transparent">
                          <Rating
                            name="calification-delivery"
                            size="medium"
                            value={this.state.deliveryClf.value}
                            onChange={(event, newValue) => {
                              this.setState({
                                deliveryClf: {
                                  ...this.state.deliveryClf,
                                  value: newValue
                                }
                              });
                            }}
                          />
                        </Box>
                      </div>
                      <TextField
                        style={{ width: "100%", marginBottom: "10px" }}
                        id="filled-multiline-static"
                        label="Comentario"
                        multiline
                        rows={4}
                        defaultValue={""}
                        variant="filled"
                        onChange={(event) => {
                          this.setState({
                            deliveryClf: {
                              ...this.state.deliveryClf,
                              observation: event.target.value
                            }
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
                : null}

              {this.state.openModalQualificationAssembly ?
                <div >
                  {this.props.asbQualResult.showAlert && <div className="col-6 offset-3" style={{ marginBottom: "15px" }}><Alert severity="error">{this.props.asbQualResult.message}</Alert></div>}
                  <div className="d-flex justify-content-start" style={{ borderTop: "2px dotted", paddingTop: "20px", borderTopColor: "#c6c6c6" }}>
                    <div className="" style={{ marginTop: "30px", marginLeft: "10px", marginRight: "10px" }}><img style={{ borderRadius: "20px" }} width={100} height={100} alt={imgCalArmado} src={imgCalArmado} /></div>
                    <div className="mx-auto" style={{ width: "80%" }}>
                      <div className="d-flex align-items-center flex-column" style={{ marginBottom: "10px", marginRight: "10px" }}>
                        <div className="col-12 center-horizontal">
                          <Box component="fieldset" mb={0} borderColor="transparent">
                            <Rating
                              name="calification-assembly"
                              size="medium"
                              value={this.state.assemblyClf.value}
                              onChange={(event, newValue) => {
                                this.setState({
                                  assemblyClf: {
                                    ...this.state.assemblyClf,
                                    value: newValue
                                  }
                                });
                              }}
                            />
                          </Box>
                        </div>
                        <TextField
                          style={{ width: "100%", marginBottom: "10px" }}
                          id="filled-multiline-static"
                          label="Comentario"
                          multiline
                          rows={4}
                          defaultValue={""}
                          variant="filled"
                          onChange={(event) => {
                            this.setState({
                              assemblyClf: {
                                ...this.state.assemblyClf,
                                observation: event.target.value
                              }
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                : null}
              <div className="col-12 btn-content mt-2">
                <Button type="button" className={"btn btn-cancel"} variant="outlined" onClick={() => this.handleCloseModalQualificationSummary("cancel")}>Cancelar</Button>
                <BtnRed title="Enviar" isLoggedIn={this.props.lvrQualResult.loader}></BtnRed>
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* </Fade> */}
    </Modal>;

    // Lista de productos
    const PRODUCT_LIST = <div>
      {MODAL_SHOW_MOTIVO_NO_CUMPLIMIENTO}
      {MODAL_CALIFICATION_DELIVERY}
      {MODAL_CALIFICATION_ASSEMBLY}
      {/*MODAL_CALIFICATION_SUMMARY*/}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        style={stylesModal.modal}
        open={this.state.openModalQualification}
        onClose={this.handleCloseModalQualification}
        closeAfterTransition
        className="modal-rate-product"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={this.state.openModalQualification}>
          <div style={stylesModal.paper}>
            <form onSubmit={this.sendQualification}>
              <div className="row">
                <div className="col-12">
                  <p id="transition-modal-description" style={stylesModal.description}>Cómo calificas tu experiencia con:</p>
                  <p id="transition-modal-title" style={stylesModal.title}>{this.state.productSelected.nom_producto}</p>
                </div>
                {this.props.prodQualResult.showAlert && <div className="col-6 offset-3" style={{ marginBottom: "15px" }}><Alert severity="error">{this.props.prodQualResult.message}</Alert></div>}
                <div className="col-12 center-horizontal" style={{ marginBottom: "10px" }}><img style={{ borderRadius: "20px" }} width={150} height={150} alt={this.state.productSelected.nom_producto} src={this.state.productSelected.url_thumbnail ? this.state.productSelected.url_thumbnail : imgNotLoad} /></div>
                <div className="col-12 center-horizontal">
                  <Box component="fieldset" mb={3} borderColor="transparent">
                    <Rating
                      name="simple-controlled"
                      size="large"
                      value={this.state.productClf.value}
                      onChange={(event, newValue) => {
                        this.setState({
                          productClf: {
                            ...this.state.productClf,
                            value: newValue
                          }
                        });
                      }}
                    />
                  </Box>
                </div>
                <div className="col-sm-8 col-12 v-center">
                  <TextField
                    style={{ width: "100%" }}
                    id="filled-multiline-static"
                    label="Comentario"
                    multiline
                    rows={4}
                    defaultValue={this.state.productClf.observation}
                    variant="filled"
                    onChange={(event) => {
                      this.setState({
                        productClf: {
                          ...this.state.productClf,
                          observation: event.target.value
                        }
                      });
                    }}
                  />
                </div>
                <div className="col-12 btn-content mt-2">
                  <Button type="button" className={"btn btn-cancel"} variant="outlined" onClick={() => this.handleCloseModalQualification("cancel")}>Cancelar</Button>
                  <BtnRed title="Enviar" isLoggedIn={this.props.prodQualResult.loader}></BtnRed>
                </div>
              </div>
            </form>
          </div>
        </Fade>
      </Modal>
      {
        this.props.subloader ? <div className="mt-2 col-12 text-center"><img className="mt-1" src={loadingCircle} alt="loadingCircle" /></div>
          :
          (_productList.length > 0) ?
            _productList.map((compra, idx) => {
              let comprasSize = _productList.length;

              let spanEstado;

              if (compra.estado === 'ENTREGADA') {
                spanEstado = <span className="btn-tag-estado btn-no_en_proceso">Entregada</span>
              } else {
                spanEstado = <span className="btn-tag-estado btn-en_proceso">En Proceso</span>
              }

              return (
                <div
                  onClick={e => {
                    scrollToTargetAdjusted(e.target);
                  }} key={"compra" + compra.num_orden}>
                  <Accordion className={"acordion-producto" + (this.state.acordionExpanded === compra.num_orden ? "acordion-expandido " : " ") + (compra.estado === 'ENTREGADA' ? "acordion-no-proceso " : "acordion-proceso") + " " + (comprasSize === (idx + 1) ? "acordion-last" : 'acordion-normal')} expanded={this.state.acordionExpanded === compra.num_orden} onChange={this.handleExpandAcordion(compra)}>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography component={'div'} className="header-acordeon">
                        <div className={`warranty-info d-flex justify-content-between ${compra.estado === 'ENTREGADA' ? "entregada" : "procesando"}`}>
                          <div className="compra-id d-flex flex-column justify-content-between">
                            <span className="orden">Compra {compra.num_orden}</span>
                            <span className="confirmacion-pedido">Confirmación de Pedido</span>
                          </div>
                          <div className="content-estado d-flex flex-column justify-content-between">
                            {spanEstado}
                            <span className="fecha">{compra.fecha_generacion}</span>
                          </div>
                        </div>
                      </Typography>
                    </AccordionSummary>
                    {compra.products.map((product, productoIdx) => {
                      product.direccion = compra.direccion;
                      const CriterioGarantiaSeus = (product.aplica_garantia === "S") ?
                        (product.estado_garantia === "PROCESO") ?
                          <Button type="button" className="btn-garantia-proceso solicitar-garantia" onClick={() => { this.props.history.push(`/jamarAuth/warrantyCases`); }}>Garantía en Proceso</Button>
                          :
                          <Button className="boton-producto solicitar-garantia" type="button" onClick={() => { this.validPanama(product); }}><Build fontSize="small" className="mr-2" style={{ fontSize: "15px" }} /> Solicitar Garantia</Button>
                        :
                        <Button className="boton-producto terminos-condiciones" type="button" onClick={() => { this.handleOpenModalMotNoCumplimiento(product); }}><Description fontSize="small" className="mr-2" style={{ fontSize: "15px" }} /> Garantía No Vigente</Button>;

                      //console.log("product.sfConsulta ", product.sfConsulta)
                      if (product.sfConsulta.disponible === true) {
                        this.state.disponibleSf = true;
                        this.state.tipoGarantiaSf = product.sfConsulta.tipoGarantia;
                      }

                      const CriterioGarantiaSf = (product.sfConsulta.disponible === true && product.aplica_garantia === "S" && product.tipo_diagnostico == "VIRTUAL") ?
                        (product.sfConsulta.status === "Abierto" || product.sfConsulta.status === "En Proceso") ?
                          <Button type="button" className="btn-garantia-proceso solicitar-garantia">Garantía en Proceso</Button>
                          :
                          <Button className="boton-producto solicitar-garantia" type="button" onClick={() => { this.validPanama(product); }}><Build fontSize="small" className="mr-2" style={{ fontSize: "15px" }} /> Solicitar Garantia</Button>
                        :
                        CriterioGarantiaSeus;

                      let typeWarranty = "row guarantee ";
                      if (product.aplica_garantia) {
                        if (product.estado_garantia) {
                          typeWarranty += "solicitado";
                        } else {
                          typeWarranty += "solicitar";
                        }
                      } else {
                        typeWarranty += "terminos";
                      }
                      return (
                        <AccordionDetails onClick={e => e.stopPropagation()} className="detalle-compra pl-0" key={"producto-" + productoIdx}>
                          <Typography component={'div'} className="col-lg-12 contenedor-guarantee">
                            <div className="row">
                              <h6 className="title-product mb-3">{(product.nom_producto) ? product.nom_producto : '-'}</h6>
                            </div>
                            <div key={"product" + productoIdx} className={typeWarranty}>
                              <div className="col-lg-4 col-md-4 col-sm-4 col-4 pl-0 pr-1 xcontenedor-imag text-center">
                                <img className="img-guarantee" width={120} height={120} alt={product.nom_producto} src={product.url_thumbnail ? product.url_thumbnail : imgNotLoad} />
                              </div>
                              <div className="col-lg-8 col-md-8 col-sm-8 col-8 pr-0 d-flex flex-column justify-content-between">
                                <div className="row">
                                  <span className="titulo-propiedad col-lg-6 col-md-6 col-sm-6 col-6 pr-0">Dimensiones</span>
                                  <span className="valor-propiedad col-lg-6 col-md-6 col-sm-6 col-6 pl-0">{(product.dimensiones) ? product.dimensiones : '-'}</span>
                                </div>
                                <div className="row">
                                  <span className="titulo-propiedad col-lg-6 col-md-6 col-sm-6 col-6 pr-0">Cantidad</span>
                                  <span className="valor-propiedad col-lg-6 col-md-6 col-sm-6 col-6 pl-0">{(product.cantidad) ? product.cantidad : '-'}</span>
                                </div>
                                <div className="row">
                                  <span className="titulo-propiedad col-lg-6 col-md-6 col-sm-6 col-6 pr-0">Refencia</span>
                                  <span className="valor-propiedad col-lg-6 col-md-6 col-sm-6 col-6 pl-0">{(product.cod_producto) ? product.cod_producto : '-'}</span>
                                </div>
                                {compra.estado !== 'PROCESO' &&
                                  <div className="row">
                                    <span className="titulo-propiedad col-lg-6 col-md-6 col-sm-6 col-6 pr-0">Estado</span>
                                    <span className="estado-propiedad col-lg-6 col-md-6 col-sm-6 col-6 pl-0 pr-0">
                                      <span className={`text-estado-factura  ${compra.estado === 'ENTREGADA' ? "entregada" : "procesando"}`}>
                                        &nbsp;{compra.estado === 'RUTA' ? <LocalShippingIcon style={{ fontSize: 20 }} /> : ''} {this.getStatus(compra.estado).message}&nbsp;&nbsp;
                                      </span>
                                    </span>
                                  </div>
                                }
                              </div>
                              <div className="d-flex flex-column w-100 my-4 align-items-center justify">
                                <div className="row mt-1 w-100">
                                  <div className="col-12">
                                    {
                                      (product.estado_factura) ?
                                        (product.estado_factura === "ENTREGADA") ?
                                          CriterioGarantiaSf
                                          : (product.estado_factura === "RUTA") ? null : null
                                        : null
                                    }
                                  </div>
                                </div>
                                {['CONFIRMADA', 'ALISTADA', 'ALISTAMIENTO', 'RUTA'].includes(compra.estado) &&
                                  <div className="row mt-1 w-100">
                                    <div className="col-12">
                                      {compra.estado === 'RUTA' && compra.mostrar_urlmapa === 'S' ?
                                        <Button type="button" className="btn-garantia-proceso solicitar-garantia" onClick={() => { this.changeCurrentPage("TERMS_CONDITIONS_TRACKING"); this.selectOrder(compra) }}><RoomIcon /> Ver en tiempo real</Button>
                                        :
                                        <Button type="button" className="btn-garantia-proceso solicitar-garantia" onClick={() => { this.changeCurrentPage("TERMS_CONDITIONS_TRACKING"); this.selectOrder(compra) }}>Ver Estado de Entrega</Button>
                                      }
                                    </div>
                                  </div>
                                }
                                {compra.estado_armado !== 'REALIZADO' && compra.aplica_armado === 'S' && product.armable === 'S' &&
                                  <div className="row mt-1 w-100">
                                    <div className="col-12">
                                      <Button type="button" className="btn-garantia-proceso solicitar-garantia" onClick={() => { this.continueAssembly(compra) }}>
                                        <img
                                          style={{ marginRight: 4 }}
                                          src={EstadoArmadoIcon}
                                          alt="icono-estado"
                                        /> Ver estado de armado
                                      </Button>
                                    </div>
                                  </div>
                                }
                                {compra.estado === 'ENTREGADA' &&
                                  <div className="row mt-1 w-100">
                                    <div className="col-12">
                                      {!product.calification_value ?
                                        <Button className="boton-producto califica-producto" type="button" onClick={() => { this.handleOpenModalQualification(product, compra.consec_orden) }}><Star fontSize="small" className="mr-2" style={{ fontSize: "15px" }} /> Califica tu producto</Button>
                                        :
                                        <div className="text-center" style={{ width: "100%" }}>
                                          {product.calification_value === 1 &&
                                            <div>
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                            </div>
                                          }
                                          {product.calification_value === 2 &&
                                            <div>
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                            </div>
                                          }
                                          {product.calification_value === 3 &&
                                            <div>
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                            </div>
                                          }
                                          {product.calification_value === 4 &&
                                            <div>
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                            </div>
                                          }
                                          {product.calification_value === 5 &&
                                            <div>
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                              <Star fontSize="small" className="mr-2" style={{ fontSize: "20px", color: "#f6d71a" }} />
                                            </div>
                                          }
                                        </div>
                                      }
                                    </div>
                                  </div>
                                }
                              </div>
                            </div>
                          </Typography>
                        </AccordionDetails>
                      )
                    })}
                  </Accordion>

                </div>
              )
            }) :
            <div className="row justify-content-center">
              <h6 className="mt-5"><Icon style={{ position: "relative", top: "6px" }}>error_outline</Icon> No se encontró ninguna coincidencia </h6>
            </div>
      }
      <div className="pagination-container">
        <Pagination
          onChange={page => this.handleChangePage(page)}
          pageSize={5}
          current={this.state.pagination.page}
          total={totalNumberOfPurchases}
        />
      </div>
    </div>;

    const TERMS_CONDITIONS_TRAKING = <div className="row justify-content-center terminosy">
      <div className="col-lg-11 content-terminios">
        <h3 className="mb-4 text-center" style={{ display: "block", width: "100%" }}>
          Términos y condiciones de la entrega
        </h3>
        <div className="content" style={{ 'textAlign': 'justify' }} dangerouslySetInnerHTML={{ __html: this.nl2br(this.state.orderSelected.term_cond_entrega) }}></div>
        {
          this.state.showAlertTermsTracking ? <Alert className="mb-4" severity="warning" style={{ justifyContent: "center" }} id="alertTermsAndConditions">¡Debe aceptar los términos y condiciones para continuar!</Alert> : null
        }
        <div className="acepterms mt-3">
          <FormControlLabel
            value="end"
            control={<Checkbox
              style={{ position: "relative", bottom: "2px" }}
              id="garantia"
              checked={this.state.checkboxAcceptTermsTracking}
              onChange={this.handleChangeCheckTermsTracking}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />}
            label="Si acepto las condiciones de la entrega"
            labelPlacement="end"
          />
        </div>
      </div>
      <div className="row col-12 mt-3 btn-content justify-content-around">
        <Button type="button" className="buttons-accept-cancel btn-cancel-default" onClick={() => { this.changeCurrentPage("PRODUCT_LIST"); this.inactiveMenuEntrega(); }} variant="contained" color="primary">Cancelar</Button>
        <Button type="button" className="buttons-accept-cancel btn-accept-default" onClick={() => this.continueTermsTracking()} variant="contained" color="primary">Continuar</Button>
      </div>
    </div>;

    const TERMS_CONDITIONS_ASSEMBLY = <div className="row justify-content-center terminosy">
      <div className="col-lg-11 content-terminios">
        <h3 className="mb-4 text-center" style={{ display: "block", width: "100%" }}>
          Términos y condiciones del armado
        </h3>
        <div className="content" style={{ 'textAlign': 'justify' }} dangerouslySetInnerHTML={{ __html: this.nl2br(this.state.orderSelected.term_cond_armado) }}></div>
        {
          this.state.showAlertTermsAssembly ? <Alert className="mb-4" severity="warning" style={{ justifyContent: "center" }} id="alertTermsAndConditions">¡Debe aceptar los términos y condiciones para continuar!</Alert> : null
        }
        <div className="acepterms mt-3">
          <FormControlLabel
            value="end"
            control={<Checkbox
              style={{ position: "relative", bottom: "2px" }}
              id="garantia"
              checked={this.state.checkboxAcceptTermsAssembly}
              onChange={this.handleChangeCheckTermsAssembly}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />}
            label="Si acepto las condiciones del armado"
            labelPlacement="end"
          />
        </div>
      </div>
      <div className="row col-12 mt-3 btn-content justify-content-around">
        <Button type="button" className="buttons-accept-cancel btn-cancel-default" onClick={() => { this.changeCurrentPage("PRODUCT_LIST"); this.inactiveMenuEntrega(); }} variant="contained" color="primary">Cancelar</Button>
        <Button type="button" className="buttons-accept-cancel btn-accept-default" onClick={() => this.continueTermsAssembly()} variant="contained" color="primary">Continuar</Button>
      </div>
    </div>;

    const VIEW_TRAKING = <div className="row justify-content-center terminosy">
      <div className="col-lg-11 content-terminios">
        <div className="mb-4 text-center" style={{ display: "block", width: "100%" }}>
          <Button type="button" className="btn-garantia-proceso btn-tracking">{this.state.orderSelected.estado}</Button>
        </div>
        <div className="content">
          {/* CONTENIDO */}
          <div dangerouslySetInnerHTML={{ __html: `<iframe src="${this.state.orderSelected.urlmapa}" width="480" height="550"></iframe>` }} />
        </div>
      </div>
      <div className="row col-12 mt-3 btn-content justify-content-around">
        <Button type="button" className="buttons-accept-cancel btn-cancel-default" onClick={() => { this.changeCurrentPage("PRODUCT_LIST"); this.inactiveMenuEntrega(); }} variant="contained" color="primary">Volver</Button>
      </div>
    </div>;

    // Terminos y condiciones
    const TERMS_CONDITIONS =
      <div className="row justify-content-center terminosy">
        <div className="col-lg-11 content-terminios">
          <h3 className="mb-4 text-center" style={{ display: "block", width: "100%" }}>
            Términos y condiciones de garantías
          </h3>
          <div className="content" dangerouslySetInnerHTML={{ __html: this.nl2br(this.state.productSelected.term_cond) }}></div>
          {
            this.state.showAlertTermsAndConditions ? <Alert className="mb-4" severity="warning" style={{ justifyContent: "center" }} id="alertTermsAndConditions">¡Debe aceptar los términos y condiciones para continuar!</Alert> : null
          }
          <div className="acepterms mt-3">
            <FormControlLabel
              value="end"
              control={<Checkbox
                style={{ position: "relative", bottom: "2px" }}
                id="garantia"
                checked={this.state.checkboxAcceptTermsAndConditions}
                onChange={this.handleChangeCheckTermsAndConditions}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />}
              label="Si acepto las condiciones de mi servicio de garantía"
              labelPlacement="end"
            />
          </div>
        </div>
        <div className="row col-12 mt-3 btn-content justify-content-around">
          <Button type="button" className="buttons-accept-cancel btn-cancel-default" onClick={() => this.changeCurrentPage("PRODUCT_LIST")} variant="contained" color="primary">Cancelar</Button>
          <Button type="button" className="buttons-accept-cancel btn-accept-default" onClick={() => this.continueTermsAndConditions()} variant="contained" color="primary">Continuar</Button>
        </div>
      </div>;

    // Mostrar fecha por defecto
    const DATE_DEFAULT =
      this.state.globalLoading ? <div className="mt-2 col-12 text-center"><img className="mt-1" src={loadingCircle} alt="loadingCircle" /></div> : (
        <div className="row justify-content-center date-default-page">
          <h6 className="mt-3 mb-4 col-12 text-center">La fecha y jornada prevista para su diagnóstico de garantía es:</h6>
          <h4 className="col-12 text-center"><b>{(this.state.dateDiagnosticSelect && this.state.dateDiagnosticSelect.date) ? this.dateformat(this.state.dateDiagnosticSelect.date) : "-"}</b></h4>
          <h6 className="mt-2 mb-4">En la Jornada: <b>{(this.state.dateDiagnosticSelect && this.state.dateDiagnosticSelect.workingDayArray && this.state.dateDiagnosticSelect.workingDayArray.length > 0) ? ((this.state.dateDiagnosticSelect.workingDayArray[0] === "J1") ? "Mañana" : "Tarde") : "-"}</b></h6>
          <p className="col-12 text-center" onClick={() => this.changeCurrentPage("SET_DATE_DIAGNOSTIC")}>
            <span className="change-date-and-working-date-and-directions" style={{ color: "#00B5AD", fontSize: "17px", fontWeight: "600" }}>Cambiar fecha y jornada</span>
          </p>
          <div className="row col-12 mt-3 btn-content justify-content-around">
            <Button type="button" className="buttons-accept-cancel btn-cancel-default" onClick={() => this.changeCurrentPage("PRODUCT_LIST")} variant="contained" color="primary">Cancelar</Button>
            <Button type="button" className="buttons-accept-cancel btn-accept-default" onClick={() => (this.state.productSelected && this.state.productSelected.tipo_diagnostico === "VIRTUAL") ? this.changeCurrentPage("DESC_PROBLEM") : this.changeCurrentPage("INFO_BIOSECURITY")} variant="contained" color="primary">Aceptar</Button>
          </div>
        </div>
      );

    // Establecer fecha por defecto
    const SET_DATE_DIAGNOSTIC = <div className="row justify-content-center date-default-page">
      <h6 className="mt-3 mb-4 col-12 text-center">Seleccione la fecha y la jornada para su diagnostico de garantía</h6>
      <div className="mt-1 mb-4 col-12 text-center">
        <FormControl className="selects-width60-left">
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">Fecha </InputLabel>
          <Select
            labelId="fecha-diagnostico"
            id="fecha-diagnostico"
            value={this.state.dateDiagnosticSelect}
            onChange={(event) => this.handleChangeDateDiagnostic(event)}
            displayEmpty
            className=""
          >
            {
              this.state.dateDiagnostic.dateDiagnosticArray.map((date, index) => {
                return <MenuItem key={"date" + index} value={date}>{this.dateformat(date.date)}</MenuItem>
              })
            }
          </Select>
        </FormControl>
      </div>
      <div className="mt-1 mb-4 col-12 text-center">
        <FormControl className="selects-width60-left">
          <InputLabel shrink id="demo-simple-select-placeholder-label-label">Jornada </InputLabel>
          <Select
            labelId="jornada-diagnostico"
            id="jornada-diagnostico"
            value={this.state.dateDiagnosticSelect.workingDaySelect}
            onChange={(event) => this.handleChangeWorkingDayDiagnostic(event)}
            displayEmpty
            className=""
            disabled={!this.state.dateDiagnosticSelect.workingDayArray || this.state.dateDiagnosticSelect.workingDayArray.length === 0}
          >
            {
              (this.state.dateDiagnosticSelect.workingDayArray && this.state.dateDiagnosticSelect.workingDayArray.length > 0) ?
                this.state.dateDiagnosticSelect.workingDayArray.map((workingsDay, index) => {
                  return <MenuItem key={"workingsDay" + index} value={workingsDay}>{(workingsDay === "J1") ? "Mañana" : "Tarde"}</MenuItem>
                }) : null
            }
          </Select>
        </FormControl>
      </div>
      <div className="row col-12 btn-content mt-3 justify-content-around">
        <Button type="button" className="buttons-accept-cancel btn-cancel-default" onClick={() => this.changeCurrentPage("PRODUCT_LIST")} variant="contained" color="primary">Cancelar</Button>
        <Button type="button" className="buttons-accept-cancel btn-accept-default" onClick={() => (this.state.productSelected && this.state.productSelected.tipo_diagnostico === "VIRTUAL") ? this.changeCurrentPage("DESC_PROBLEM") : this.changeCurrentPage("INFO_BIOSECURITY")} variant="contained" color="primary">Aceptar</Button>
      </div>
    </div >;

    // Escoger dirección
    const SELECT_DIRECTIONS =
      this.state.globalLoading ? <div className="mt-2 col-12 text-center"><img className="mt-1" src={loadingCircle} alt="loadingCircle" /></div> : (
        <div className="row justify-content-center date-default-page">
          <h6 className="mt-3 mb-4 col-12 text-center">En que dirección desea que se realice el diagnóstico de sus productos</h6>
          <div className="mt-1 mb-4 col-12 text-center">
            <FormControl className="selects-width60-left">
              <InputLabel shrink>Destino de Servicio </InputLabel>
              <Select
                labelId="destino-servicio"
                id="destino-servicio"
                value={this.state.selectDirections.selectDirectionsSelect}
                onChange={(event) => this.handleChangeSelectDirections(event)}
                displayEmpty
                className=""
              >
                {
                  this.state.selectDirections.selectDirectionsArray.map((direction, index) => {
                    return <MenuItem key={"direction" + index} value={direction}>{direction.dir}  |  {direction.nom_depto ? direction.nom_depto : ""}-{direction.nom_mnpo} - {direction.nom_bar}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
          <p className="col-12 text-center" onClick={() => this.changeCurrentPage("NEW_DIRECTION")}>
            <span className="change-date-and-working-date-and-directions" style={{ color: "#00B5AD", fontSize: "17px", fontWeight: "600" }}>Agregar nueva dirección</span>
          </p>
          <div className="row col-12 mt-3 btn-content justify-content-around">
            <Button type="button" className="buttons-accept-cancel btn-cancel-default" onClick={() => this.changeCurrentPage("PRODUCT_LIST")} variant="contained" color="primary">Cancelar</Button>
            <Button type="button" className="buttons-accept-cancel btn-accept-default" onClick={() => this.changeCurrentPage(this.state.virtual ? "EMAIL_SELECT" : "DATE_DEFAULT")} variant="contained" color="primary">Agendar</Button>
          </div>
        </div>
      );

    const PHONE_SELECT =
      this.state.globalLoading ? <div className="mt-2 col-12 text-center"><img className="mt-1" src={loadingCircle} alt="loadingCircle" /></div> : (
        <div className="row justify-content-center desc-problem">
          <div className="col-lg-11">
            <h5 className="text-center">Seleccione un número de telefono</h5>
            <form onSubmit={this.submitVirtualWarranty}>
              <FormControl style={{ width: "100%" }}>
                <InputLabel shrink>Número de telefono</InputLabel>
                <Select
                  labelId="telefono-usuario"
                  id="telefono-usuario"
                  value={this.state.userPhone}
                  onChange={(event) => this.handlePhoneChange(event)}
                  required
                >
                  {
                    this.state.userPhones.map((phone) => {
                      return <MenuItem key={phone} value={phone}>{phone}</MenuItem>
                    })
                  }
                </Select>
              </FormControl>
              <p className="col-12 text-center" onClick={() => this.changeCurrentPage("ADD_PHONE")}>
                <span className="add-phone-link" style={{ color: "#00B5AD", fontSize: "17px", fontWeight: "600" }}>Agregar nuevo número</span>
              </p>
              <div className="row justify-content-center mt-5">
                <div className="content-btn-problem btn-content mb-3">
                  <Button onClick={() => this.changeCurrentPage("PRODUCT_LIST")}>Cancelar</Button>
                  <Button type="submit">Enviar</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      );

    const EMAIL_SELECT =
      <div className="row justify-content-center desc-problem">
        <div className="col-lg-11">
          <h5 className="text-center">Seleccione un correo electrónico</h5>
          <FormControl style={{ width: "100%" }}>
            <InputLabel shrink>Correo electrónico</InputLabel>
            <Select
              labelId="correo-usuario"
              id="correo-usuario"
              value={this.state.userMail}
              onChange={(event) => this.handleMailChange(event)}
              required
            >
              <MenuItem key={this.state.userData.email} value={this.state.userData.email}>{this.state.userData.email}</MenuItem>
            </Select>
          </FormControl>
          <p className="col-12 text-center" onClick={() => this.changeCurrentPage("ADD_EMAIL")}>
            <span className="add-phone-link" style={{ color: "#00B5AD", fontSize: "17px", fontWeight: "600" }}>Agregar otro correo electrónico</span>
          </p>
          <div className="row justify-content-center mt-5">
            <div className="content-btn-problem btn-content mb-3">
              <Button onClick={() => this.changeCurrentPage("PRODUCT_LIST")}>Cancelar</Button>
              <Button onClick={() => this.changeCurrentPage("PHONE_SELECT")} variant="contained" color="primary">Continuar</Button>
            </div>
          </div>
        </div>
      </div>

    const ADD_EMAIL =
      <div className="row justify-content-center desc-problem">
        <div className="col-lg-11">
          <h5 className="text-center">Inserte su correo electrónico</h5>
          <TextField
            className="mat-textarea col-lg-12 my-4"
            label="Correo electrónico"
            placeholder="Correo electrónico"
            name="userMail" onChange={this.handleMailChange} value={this.state.userMail}
            required
            inputProps={{ maxLength: 200 }}
          />
          <div className="row justify-content-center">
            <div className="content-btn-problem btn-content mb-3">
              <Button onClick={() => this.changeCurrentPage("PRODUCT_LIST")}>Cancelar</Button>
              <Button onClick={() => this.changeCurrentPage("PHONE_SELECT")} variant="contained" color="primary">Enviar</Button>
            </div>
          </div>
        </div>
      </div>

    const ADD_PHONE =
      <div className="row justify-content-center desc-problem">
        <div className="col-lg-11">
          <h5 className="text-center">Inserte un número de telefono</h5>
          <form onSubmit={this.submitVirtualWarranty}>
            <TextField
              className="mat-textarea col-lg-12 my-4"
              label="Número de telefono"
              placeholder="Número de telefono"
              name="userPhone" onChange={this.handlePhoneChange} value={this.state.userPhone}
              required
              inputProps={{ maxLength: 200 }}
            />
            <div className="row justify-content-center">
              <div className="content-btn-problem btn-content mb-3">
                <Button onClick={() => this.changeCurrentPage("PRODUCT_LIST")}>Cancelar</Button>
                <Button type="submit">Enviar</Button>
              </div>
            </div>
          </form>
        </div>
      </div>


    // Establecer nueva dirección
    const NEW_DIRECTION = <div className="row justify-content-center direction-detail">
      <form onSubmit={this.generateDirection} className="col-lg-11">
        <h6 className="mt-3 mb-4 col-12 text-center">En que dirección desea que se realice el diagnostico de sus productos</h6>
        <div className="row mt-1 mb-4">
          <div className="col-12">
            <FormControl>
              <InputLabel shrink>Departamento </InputLabel>
              <Select
                labelId="destino-departamento"
                id="destino-departamento"
                value={this.state.selectDepartment.selectDepartmentSelect}
                onChange={(event) => this.handleChangeSelectDepartment(event)}
                displayEmpty
                className=""
                required
              >
                {
                  this.state.selectDepartment.selectDepartmentArray.map((department, index) => {
                    return <MenuItem key={"department" + index} value={department}>{department.descripcion}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="mt-1 mb-4 row">
          <div className="col-12">
            <FormControl>
              <InputLabel shrink>Ciudad </InputLabel>
              <Select
                labelId="destino-ciudad"
                id="destino-ciudad"
                value={this.state.selectCity.selectCitySelect}
                onChange={(event) => this.handleChangeSelectCity(event)}
                displayEmpty
                className=""
                required
                disabled={(!this.state.selectCity.selectCitySelect || !this.state.selectCity.selectCitySelect.codigo || this.state.selectCity.selectCitySelect.codigo === '-1')}
              >
                {
                  this.state.selectCity.selectCityArray.map((city, index) => {
                    return <MenuItem key={"city" + index} value={city}>{city.descripcion}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="mt-1 mb-4 row">
          <div className="col-12">
            <FormControl>
              <InputLabel shrink>Barrio </InputLabel>
              <Select
                labelId="destino-barrio"
                id="destino-barrio"
                value={this.state.selectNeighborhood.selectNeighborhoodSelect}
                onChange={(event) => this.handleChangeSelectNeighborhood(event)}
                displayEmpty
                className=""
                required
                disabled={(!this.state.selectNeighborhood.selectNeighborhoodSelect || !this.state.selectNeighborhood.selectNeighborhoodSelect.codigo || this.state.selectNeighborhood.selectNeighborhoodSelect.codigo === '-1')}
              >
                {
                  this.state.selectNeighborhood.selectNeighborhoodArray.map((neighborhood, index) => {
                    return <MenuItem key={"neighborhood" + index} value={neighborhood}>{neighborhood.descripcion}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
        </div>
        <div className="row">
          <div className="col-6">
            <FormControl className="width100 text-left">
              <InputLabel shrink>Dirección </InputLabel>
              <Select
                labelId="destino-direccion"
                id="destino-direccion"
                value={this.state.selectIndexDirection.selectIndexDirectionSelect}
                onChange={(event) => this.handleChangeSelectIndexDirection(event)}
                displayEmpty
                className=""
                required
              >
                {
                  this.state.selectIndexDirection.selectIndexDirectionArray.map((direction, index) => {
                    return <MenuItem key={"direction" + index} value={direction}>{direction.descripcion}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
          <div className="col-2 pl-0">
            <TextField type="text" autoComplete="off" className="width100" name="via" onChange={this.changeHandlerNewDirection} value={this.state.newDirection.via} label="&nbsp;" required />
          </div>
          <div className="col-2 pl-0">
            <TextField type="text" autoComplete="off" className="width100" name="crucero" onChange={this.changeHandlerNewDirection} value={this.state.newDirection.crucero} label="&nbsp;" required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">#</InputAdornment>
                ),
              }}
            />
          </div>
          <div className="col-2 pl-0">
            <TextField type="text" autoComplete="off" className="width100" name="placa" onChange={this.changeHandlerNewDirection} value={this.state.newDirection.placa} label="&nbsp;" required
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">-</InputAdornment>
                ),
              }}
            />
          </div>
        </div>
        <div className="mt-1 mb-4 row">
          <div className="col-8">
            <FormControl className="width100 text-left">
              <InputLabel shrink>Interior 1 </InputLabel>
              <Select
                labelId="destino-direccion"
                id="destino-direccion"
                value={this.state.selectInterior1.selectInterior1Select}
                onChange={(event) => this.handleChangeSelectInterior1(event)}
                displayEmpty
                className=""
              >
                {
                  this.state.selectInterior1.selectInterior1Array.map((interior1, index) => {
                    return <MenuItem key={"interior1" + index} value={interior1}>{interior1.descripcion}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
          <div className="col-4">
            <TextField type="text" autoComplete="off" className="width100" name="nInterior1"
              onChange={this.changeHandlerNewDirection} value={this.state.newDirection.nInterior1} label="&nbsp;"
              disabled={(!this.state.selectInterior1.selectInterior1Select || !this.state.selectInterior1.selectInterior1Select.codigo || this.state.selectInterior1.selectInterior1Select.codigo === '-2')}
              required={(this.state.selectInterior1.selectInterior1Select && this.state.selectInterior1.selectInterior1Select.codigo && this.state.selectInterior1.selectInterior1Select.codigo !== '-2')}
            />
          </div>
        </div>
        <div className="mt-1 mb-4 row">
          <div className="col-8">
            <FormControl className="width100 text-left">
              <InputLabel shrink>Interior 2 </InputLabel>
              <Select
                labelId="destino-direccion"
                id="destino-direccion"
                value={this.state.selectInterior2.selectInterior2Select}
                onChange={(event) => this.handleChangeSelectInterior2(event)}
                displayEmpty
                className=""
              >
                {
                  this.state.selectInterior2.selectInterior2Array.map((interior2, index) => {
                    return <MenuItem key={"interior2" + index} value={interior2}>{interior2.descripcion}</MenuItem>
                  })
                }
              </Select>
            </FormControl>
          </div>
          <div className="col-4">
            <TextField type="text" autoComplete="off" className="width100" name="nInterior2"
              onChange={this.changeHandlerNewDirection} value={this.state.newDirection.nInterior2} label="&nbsp;"
              disabled={(!this.state.selectInterior2.selectInterior2Select || !this.state.selectInterior2.selectInterior2Select.codigo || this.state.selectInterior2.selectInterior2Select.codigo === '-2')}
              required={(this.state.selectInterior2.selectInterior2Select && this.state.selectInterior2.selectInterior2Select.codigo && this.state.selectInterior2.selectInterior2Select.codigo !== '-2')}
            />
          </div>
        </div>
        <div className="mt-1 mb-4 row">
          <div className="col-12">
            <TextField type="text" autoComplete="off" name="senia" onChange={this.changeHandlerNewDirection} value={this.state.newDirection.senia} label="Seña" />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="content-btn-direccion btn-content mb-3">
            <Button type="button" onClick={() => this.changeCurrentPage("PRODUCT_LIST")} variant="contained">Cancelar</Button>
            <Button type="submit" variant="contained">Seguir</Button>
          </div>
        </div>
      </form>
    </div >;

    // descripcion del problema virtual
    const DESC_PROBLEM = <div className="row justify-content-center desc-problem">
      <div className="col-lg-11">
        <h4 className="text-center">{(this.state.productSelected && this.state.productSelected.nom_producto) ? this.state.productSelected.nom_producto : "-"}</h4>
        <h5 className="text-center">Descríbanos el problema</h5>
        <form onSubmit={() => this.changeCurrentPage("EVIDENCIA_PHOTO")}>
          {/* <TextField
            className="mat-textarea col-lg-12 mb-2"
            label="¿Qué pasó?"
            placeholder="Escriba aquí su respuestas…"
            multiline
            name="quePaso" onChange={this.changeHandlerProblemDescription} value={this.state.problemDescription.quePaso}
            required
            inputProps={{ maxLength: 200 }}
          /> */}
          <TextField
            className="mat-textarea col-lg-12 mb-2"
            label="¿Qué tiene el producto?"
            placeholder="Escriba aquí su respuestas…"
            multiline
            name="queTieneElProducto" onChange={this.changeHandlerProblemDescription} value={this.state.problemDescription.queTieneElProducto}
            required
            inputProps={{ maxLength: 150 }}
          />
          <TextField
            className="mat-textarea col-lg-12 mb-2"
            label="¿En qué parte?"
            placeholder="Escriba aquí su respuestas…"
            multiline
            name="enQueParte" onChange={this.changeHandlerProblemDescription} value={this.state.problemDescription.enQueParte}
            required
            inputProps={{ maxLength: 50 }}
          />
          <FormControl className="col-lg-12 mb-4">
            <InputLabel id="select-problem">El daño presentado se encuentra en:</InputLabel>
            <Select
              labelId="select-problem"
              id="idselect-problem"
              value={this.state.problemDescription.dondeSeEncuentra}
              name="dondeSeEncuentra"
              onChange={this.changeHandlerProblemDescription}
              required
            >
              <MenuItem value={"En una pieza del producto"}>En una pieza del producto</MenuItem>
              <MenuItem value={"En todo el producto"}>En todo el producto</MenuItem>
            </Select>
          </FormControl>
          <div className="row justify-content-center">
            <div className="content-btn-problem btn-content mb-3">
              <Button onClick={() => this.changeCurrentPage("PRODUCT_LIST")}>Cancelar</Button>
              <Button type="submit">Enviar</Button>
            </div>
          </div>
        </form>
      </div>
    </div>

    // descripcion del problema Fisico
    const DESC_PROBLEM_FISICO = <div className="row justify-content-center desc-problem">
      <div className="col-lg-11">
        <h4 className="text-center">{(this.state.productSelected && this.state.productSelected.nom_producto) ? this.state.productSelected.nom_producto : "-"}</h4>
        <h5 className="text-center">Descríbanos el problema</h5>
        <form onSubmit={() => this.changeCurrentPage("SELECT_DIRECTIONS")}>
          <TextField
            className="mat-textarea col-lg-12 mb-2"
            label="¿Qué pasó?"
            placeholder="Escriba aquí su respuestas…"
            multiline
            name="quePaso" onChange={this.changeHandlerProblemDescription} value={this.state.problemDescription.quePaso}
            required
            inputProps={{ maxLength: 200 }}
          />
          <TextField
            className="mat-textarea col-lg-12 mb-2"
            label="¿Qué tiene el producto?"
            placeholder="Escriba aquí su respuestas…"
            multiline
            name="queTieneElProducto" onChange={this.changeHandlerProblemDescription} value={this.state.problemDescription.queTieneElProducto}
            required
            inputProps={{ maxLength: 150 }}
          />
          <TextField
            className="mat-textarea col-lg-12 mb-2"
            label="¿En qué parte?"
            placeholder="Escriba aquí su respuestas…"
            multiline
            name="enQueParte" onChange={this.changeHandlerProblemDescription} value={this.state.problemDescription.enQueParte}
            required
            inputProps={{ maxLength: 50 }}
          />
          <FormControl className="col-lg-12 mb-4">
            <InputLabel id="select-problem">El daño presentado se encuentra en:</InputLabel>
            <Select
              labelId="select-problem"
              id="idselect-problem"
              value={this.state.problemDescription.dondeSeEncuentra}
              name="dondeSeEncuentra"
              onChange={this.changeHandlerProblemDescription}
              required
            >
              <MenuItem value={"En una pieza del producto"}>En una pieza del producto</MenuItem>
              <MenuItem value={"En todo el producto"}>En todo el producto</MenuItem>
            </Select>
          </FormControl>
          <div className="row justify-content-center">
            <div className="content-btn-problem btn-content mb-3">
              <Button onClick={() => this.changeCurrentPage("PRODUCT_LIST")}>Cancelar</Button>
              <Button type="submit">Enviar</Button>
            </div>
          </div>
        </form>
      </div>
    </div>

    // evidencia fotografica
    const EVIDENCIA_PHOTO = <div className="row justify-content-center evidenc-photo">
      <div className="col-lg-11">
        <h4 className="text-center">{(this.state.productSelected && this.state.productSelected.nom_producto) ? this.state.productSelected.nom_producto : "-"}</h4>
        <h5 className="text-center mb-3">Descríbanos el problema</h5>
        <h5 className="text-center mb-2">Envíenos Fotos en las que podamos evidenciar el problema. Requerimos:</h5>
        <form onSubmit={this.setDataPhoto}>
          <div className="main-evidenc">
            <div className="row mb-3">
              <p>Foto completa del producto</p>
              <div className="col-12 cover-background">
                <div className="row justify-content-center p-2 content-img-evidenc">
                  <img alt="Foto completa del producto" src={sillaCompleta} />
                </div>
                <div className="row justify-content-center">
                  <Button className="btn-add-img" onClick={(e) => this.handleClick("photo_01")}
                    variant="contained"
                    color="secondary">
                    <Icon >add_photo_alternate</Icon> {this.state.photos.photo_01.name}
                  </Button>
                  <input type="file" id="photo_01" name="photo_01" onChange={this.handleChange} style={{ display: 'none' }} accept="image/*" />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <p>Foto de la pieza que presenta problema</p>
              <div className="col-12 cover-background">
                <div className="row justify-content-center p-2 content-img-evidenc">
                  <img alt="Foto de la pieza que presenta problema" src={sillaBase} />
                </div>
                <div className="row justify-content-center">
                  <Button className="btn-add-img" onClick={(e) => this.handleClick("photo_02")}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon >add_photo_alternate</Icon> {this.state.photos.photo_02.name}
                  </Button>
                  <input type="file" id="photo_02" name="photo_02" onChange={this.handleChange} style={{ display: 'none' }} accept="image/*" />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <p>Foto cercana del problema</p>
              <div className="col-12 cover-background">
                <div className="row justify-content-center p-2 content-img-evidenc">
                  <img alt="Foto cercana del problema" src={sillaPatas} />
                </div>
                <div className="row justify-content-center">
                  <Button className="btn-add-img" onClick={(e) => this.handleClick("photo_03")}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon >add_photo_alternate</Icon> {this.state.photos.photo_03.name}
                  </Button>
                  <input type="file" id="photo_03" name="photo_03" onChange={this.handleChange} style={{ display: 'none' }} accept="image/*" />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <p>Otra foto de la pieza que presenta problema</p>
              <div className="col-12 cover-background">
                <div className="row justify-content-center p-2 content-img-evidenc">
                  <img alt="Otra foto de la pieza que presenta problema" src={sillaPata} />
                </div>
                <div className="row justify-content-center">
                  <Button className="btn-add-img" onClick={(e) => this.handleClick("photo_04")}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon >add_photo_alternate</Icon> {this.state.photos.photo_04.name}
                  </Button>
                  <input type="file" id="photo_04" name="photo_04" onChange={this.handleChange} style={{ display: 'none' }} accept="image/*" />
                </div>
              </div>
            </div>
            <div className="row mb-3">
              <p>Foto a media distancia del problema</p>
              <div className="col-12 cover-background">
                <div className="row justify-content-center p-2 content-img-evidenc">
                  <img alt="Foto a media distancia del problema" src={sillaBasePatas} />
                </div>
                <div className="row justify-content-center">
                  <Button className="btn-add-img" onClick={(e) => this.handleClick("photo_05")}
                    variant="contained"
                    color="secondary"
                  >
                    <Icon >add_photo_alternate</Icon>{this.state.photos.photo_05.name}
                  </Button>
                  <input type="file" id="photo_05" name="photo_05" onChange={this.handleChange} style={{ display: 'none' }} accept="image/*" />
                </div>
              </div>
            </div>
            <div className="row justify-content-center">
              <div className="content-btn-evidence btn-content mb-3">
                <Button onClick={() => this.changeCurrentPage("PRODUCT_LIST")}>Cancelar</Button>
                <BtnRed title="Continuar" isLoggedIn={this.state.btnLoading}></BtnRed>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

    // INFORMACION DE BIOSEGURIDAD
    const INFO_BIOSECURITY = <div className="row justify-content-center info-biosecurity">
      <div className="col-lg-11">
        <h4 className="text-center">Información de bioseguridad y condiciones de servicio de diagnóstico en casa </h4>
        <img alt="precausiondes de bioseguridad" src={bioSecurity} />
        <div className="items-security" dangerouslySetInnerHTML={{ __html: this.nl2br(this.state.productSelected.term_bioseguridad) }}></div>
        <div className="acepterms">
          {
            this.state.showAlertTermsAndConditions ? <Alert severity="warning" style={{ justifyContent: "center" }} id="alertTermsAndConditions">¡Debe aceptar los términos y condiciones para continuar!</Alert> : null
          }
          <Checkbox
            style={{ position: "relative", bottom: "2px" }}
            checked={this.state.checkboxAcceptTermsAndConditions}
            onChange={this.handleChangeCheckTermsAndConditions}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          Si acepto las condiciones de mi servicio de garantía
        </div>
        <div className="row justify-content-center">
          <div className="content-btn-bio btn-content mt-3 mb-3">
            <Button onClick={() => this.sendSaveService()}>Confirmar</Button>
          </div>
        </div>
      </div>
    </div>

    // INFORMACION DE GRACIAS
    const GRATULATIONS = <div className="row justify-content-center message-gratulations">
      <div className="col-lg-12">
        <h4 className="text-center">Felicidades, tu servicio de diagnóstico ha sido registrado con éxito, te confirmamos por correo los pasos a seguir.</h4>
        <h4 className="text-center">{(this.state.NUM_SERVICE) ? "Tu servicio quedo registrado bajo el #" + this.state.NUM_SERVICE : ""}. </h4>
        <img alt="precausiondes de bioseguridad" src={despedida} />
        <div className="items-gratulations">
          {this.state.productSelected === 'VIRTUAL' ?
            <span>
              <h5 className="text-center mb-3"> Le recordamos de antemano:</h5>
              <p>Tener la disponibilidad de tiempo en la fecha y jornada agendada.</p>
              <p>Estar pendiente de su celular para brindar la información a tiempo. </p>
            </span>
            :
            <span></span>
          }
        </div>
        <div className="row justify-content-center">
          <div className="content-btn-gratulations btn-content mb-3">
            <Button onClick={() => this.changeCurrentPage("PRODUCT_LIST")}>OK</Button>
          </div>
        </div>
      </div>
    </div>

    switch (this.state.CURRENT_PAGE) {
      case "PRODUCT_LIST":
        return PRODUCT_LIST;
      case "TERMS_CONDITIONS":
        return TERMS_CONDITIONS;
      case "TERMS_CONDITIONS_TRACKING":
        return TERMS_CONDITIONS_TRAKING;
      case "TERMS_CONDITIONS_ASSEMBLY":
        return TERMS_CONDITIONS_ASSEMBLY;
      case "VIEW_TRAKING":
        return VIEW_TRAKING;
      case "DATE_DEFAULT":
        return DATE_DEFAULT;
      case "SET_DATE_DIAGNOSTIC":
        return SET_DATE_DIAGNOSTIC;
      case "SELECT_DIRECTIONS":
        return SELECT_DIRECTIONS;
      case "NEW_DIRECTION":
        return NEW_DIRECTION;
      case "DESC_PROBLEM":
        return DESC_PROBLEM;
      case "DESC_PROBLEM_FISICO":
        return DESC_PROBLEM_FISICO;
      case "EVIDENCIA_PHOTO":
        return EVIDENCIA_PHOTO;
      case "PHONE_SELECT":
        return PHONE_SELECT;
      case "ADD_PHONE":
        return ADD_PHONE;
      case "EMAIL_SELECT":
        return EMAIL_SELECT;
      case "ADD_EMAIL":
        return ADD_EMAIL;
      case "INFO_BIOSECURITY":
        return INFO_BIOSECURITY;
      case "GRATULATIONS":
        return GRATULATIONS;
      default:
        return PRODUCT_LIST;
    }
  }

  render() {
    // Stylos Modal
    const stylesModal = {
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      paper: {
        backgroundColor: "#fff",
        boxShadow: "0px 3px 6px #0003",
        padding: "16px 20px 24px",
        borderRadius: 19
      },
      description: {
        textAlign: "center",
        marginBottom: "1px"
      },
      title: {
        textAlign: "center",
        fontSize: "20px",
        fontWeight: 500
      }
    };
    const _LOADING = <div className="mt-2 col-12 text-center"><img className="mt-1" src={loadingCircle} alt="loadingCircle" /></div>;
    return (
      this.props.loader ?
        _LOADING :
        <>
          <div>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
            <ToastContainer />
            <Modal
              aria-labelledby="transition-modal-title"
              aria-describedby="transition-modal-description"
              style={stylesModal.modal}
              open={this.state.openModalWhatsappWarning}
              onClose={this.handleCloseModalWhatsappWarning}
              closeAfterTransition
              className="modal-rate-product"
              BackdropComponent={Backdrop}
              BackdropProps={{
                timeout: 500,
              }}>
              <Fade in={this.state.openModalWhatsappWarning}>
                <div style={stylesModal.paper}>
                  <div className="row">
                    <div className="col-12 mb-4">
                      <p id="transition-modal-description" style={stylesModal.description}><b>Ups! se ha producido un inconveniente</b></p>
                    </div>
                    <div className="col-12 center-horizontal" style={{ marginBottom: "10px" }}><img style={{ borderRadius: "20px" }} width={150} height={150} alt={WhatsappImg} src={WhatsappImg} /></div>
                    <div className="col-sm-8 col-12 v-center text-center">
                      <p>Al hacer click en continuar, serás redireccionado a nuestro chat de Whatsapp en donde uno de nuestros agentes te ayudarán.</p>
                    </div>
                    <div className="col-12 btn-content mt-2">
                      <Button type="button" className={"btn btn-cancel"} variant="outlined" onClick={() => this.handleCloseModalWhatsappWarning("cancel")}>Cancelar</Button>
                      <Button type="button" className={"btn btn-save"} variant="outlined" onClick={() => { this.goToChat() }}>Continuar</Button>
                    </div>
                  </div>
                </div>
              </Fade>
            </Modal>
            {this.renderView()}
            {/* {
              this.state.userData.company === "JA" ? <SantiPuntos />  : null 
             }   */}
          </div> 
        </>
    );
  }
}

const mapStateToProps = ({ auth, purchases }) => {
  const { userData } = auth;
  const { purchasesData, loader, subloader, expandedPurchase, lvrQualResult, asbQualResult, prodQualResult } = purchases;
  return { userData, purchasesData, loader, subloader, expandedPurchase, lvrQualResult, asbQualResult, prodQualResult }
};

export default connect(mapStateToProps, {
  activeMenuEntrega,
  setpurchasesData,
  showLoader,
  hideLoader,
  getPurchaseProducts,
  deliveryQualification,
  deliveryQualificationResult,
  assemblyQualification,
  assemblyQualificationResult,
  productQualification,
  productQualificationResult
})(Warranty);
