import React from "react";
import "./styles.scss";

const ReceptionSelectLabel = ({ isChecked, text, Icon }) => {
  return (
    <div className={`select-label-container ${isChecked && "checked"}`}>
      <div className="select-label-text">{text}</div>
      <Icon />
    </div>
  );
};

export default ReceptionSelectLabel;
