import React, { useCallback, useEffect, useState } from "react";
import http from "../../../../https/api";
import "./styles.scss";
import { encryptData } from "../../../../util/util";

const InsertCode = ({
  documentNumber,
  reception,
  isLoading,
  loadingIcon,
  setIsLoading,
  handleSetStep,
  setChangePasswordToken,
}) => {
  const [counter, setCounter] = useState(120);
  const [isLocked, setisLocked] = useState(false);
  const [code, setCode] = useState("");
  const [isVerifying, setIsVerifying] = useState(false);
  const [error, setError] = useState("");

  function formatCounter(duration) {
    var mins = ~~((duration % 3600) / 60);
    var secs = ~~duration % 60;
    var ret = "";

    ret += "" + mins + ":" + (secs < 10 ? "0" : "");
    ret += "" + secs;
    return ret;
  }
  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const getVerificationCode = useCallback(async () => {
    try {
      let response;
      let urlBase;
      let formData = { n_ide: encryptData(documentNumber) };
      if (reception === 'email') {
        urlBase = process.env.REACT_APP_INGRESO;
        response = await http.post(
          `${urlBase}users/recovery/email`, //11
          formData,
        );
      } else {
        urlBase = process.env.REACT_APP_BASE_PORTAL_BACKEND;
        response = await http.post(
          `${urlBase}recovery/phone`, //14
          formData
        );
      }
      if (response.status === 200 || response.status === 201) {

      } else {
        setisLocked(true);
      }

    }
    catch (e) {
      console.error(e)
      setError(true);
    }
    finally {
      setIsLoading(false);
    }
  }, [documentNumber, reception, setIsLoading]);


  const verifyCode = async () => {
    setError(false);
    setIsVerifying(true);
    try {

      //Elegir endpoint dependiendo de método de recuperación
      let response;
      let formData;
      let urlBase = process.env.REACT_APP_BASE_PORTAL_BACKEND;
      if (reception === 'email') {
        formData = { token: code };
        response = await http.post(
          `${urlBase}valid-token`,  //12
          formData
        );
      } else {
        formData = { n_ide: encryptData(documentNumber), token: encryptData(code) };
        response = await http.post(
          `${urlBase}valid-token/otp`, //15
          formData
        );
      }
      if (response.status === 200 || response.status === 201) {
        setChangePasswordToken(response.data);
        handleSetStep();
      } else {
        setError(true);
      }
    } catch (e) {
      setError(true);
    } finally {
      setIsVerifying(false);
    }
  };

  useEffect(() => {
    getVerificationCode();
  }, [documentNumber, setIsLoading, reception, getVerificationCode]);

  const handleReSendCode = () => {
    if (counter === 0) {
      getVerificationCode();
      setCounter(120);
    }
  };

  return isLoading || isVerifying ? (
    loadingIcon
  ) : (
    <>
      {isLocked ? (
        <div className="helper-text">
          Debe esperar para enviar un nuevo código
        </div>
      ) : (
        <>
          <div className="helper-text">
            Ingresa el código de veriﬁcación que recibiste en tu correo o
            celular.
          </div>
          <div className="code-input">
            <div className="verification-input-label">
              Código de verificación
            </div>
            <input
              type="text"
              className="verification-code-input"
              value={code}
              onChange={(event) => setCode(event.target.value)}
            />
            {error && <span className="error-message">Código incorrecto</span>}
          </div>
          <div className="countdown-timer">{formatCounter(counter)}</div>
          <div className="code-not-received">
            Si no has recibido el código al ﬁnalizar el conteo has clic en:
          </div>
          <div
            className={`re-send-code ${counter === 0 && "clickable-link"}`}
            onClick={() => handleReSendCode()}
          >
            Reenviar Código de veriﬁcación
          </div>
          {code && (
            <button className="verify-code" onClick={() => verifyCode()}>
              Verificar
            </button>
          )}
        </>
      )}
    </>
  );
};

export default InsertCode;
