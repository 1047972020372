import { all, fork, put, takeEvery } from "redux-saga/effects";
import {
    SIGNIN_USER,
    SIGNOUT_USER,
    LOGGED_USER_SESSION,
    SIGNIN_HASH_USER,
    USER_UPDATE,
    SESSION_DATA
} from "../../constants/ActionTypes";
//
import { showAuthMessage, userSignInSuccess, userDataInSuccess, sessionDataSuccess } from "../../appRedux/actions/Auth";
//
import { purchasesDataSuccess } from "../actions/Purchases";
/**
 * MÉTODO INICIO DE SESIÓN.
 * @param {*} param0
 */
function* signInUserWithEmailPassword({ payload }) {

        const { account, birthday, cellphone, document, email, first_name, id, last_name, agency, credit_type, company, country, image, negociemos, negociemos_msg } = payload;
        try {
            if (!id) {
                yield put(showAuthMessage('Error'));
            } else {
                // DATOS DE USUARIO EN SESION.
                const params = {
                        id: id,
                        account: account,
                        email: email,
                        name: first_name,
                        lastname: last_name,
                        document: document,
                        birthday: birthday,
                        cellphone: cellphone,
                        agency: agency,
                        credit_type: credit_type,
                        company: company,
                        country: country,
                        image: image,
                        negociemos: (typeof negociemos === 'boolean') ? negociemos : false,
                        negociemos_msg: negociemos_msg
                    }
                    // NÚMERO DE CUENTA DEL USUARIO.
                yield put(userSignInSuccess(account));
                // DATOS DE SESIÓN DEL USUARIO.
                yield put(userDataInSuccess(params));
                // GUARDAR DATOS USUARIOS LOCALSTORAGE.
                localStorage.setItem(SIGNIN_HASH_USER, account);
                localStorage.setItem(LOGGED_USER_SESSION, JSON.stringify(params));
            }
        } catch (error) {
            yield put(showAuthMessage(error));
        }
    }
    /**
     * MÉTODO PARA CERRAR SESIÓN.
     */
function* signOut() {
    try {
        // REMOVER DATOS DEL LOCALSTORAGE.
        localStorage.removeItem(SIGNIN_HASH_USER);
        localStorage.removeItem(LOGGED_USER_SESSION);
        localStorage.removeItem("DATAUSER");
        // NÚMERO DE CUENTA DEL USUARIO.
        yield put(userSignInSuccess(null));
        // DATOS DE SESIÓN DEL USUARIO.
        yield put(userDataInSuccess({}));
        // DATOS DE COMPRA.
        yield put(purchasesDataSuccess([]));

    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

function* userChangeData({ payload }) {
    // DATOS DEL USUARIO EN SESIÓN.
    const { birthday, cellphone, document, email, first_name, last_name, imgUrlProfile } = payload;
    //
    try {
        // OBTENER DATOS DE USUARIO DEL LOCALSTORAGE.
        const UserData = JSON.parse(localStorage.getItem(LOGGED_USER_SESSION));
        // ACTUALIZAR USUARIO EN SESION.
        const params = {
                id: UserData.id,
                account: UserData.account,
                email: email,
                name: first_name,
                lastname: last_name,
                document: document,
                birthday: birthday,
                cellphone: cellphone,
                agency: UserData.agency,
                credit_type: UserData.credit_type,
                company: UserData.company,
                country: UserData.country,
                image: imgUrlProfile
            }
            // DATOS DE SESIÓN DEL USUARIO.
        yield put(userDataInSuccess(params));
        // GUARDAR DATOS USUARIOS LOCALSTORAGE.
        localStorage.setItem(LOGGED_USER_SESSION, JSON.stringify(params));

    } catch (error) {
        yield put(showAuthMessage(error));
    }
}


function* saveSessionData({ payload }) {
    // DATOS DEL USUARIO EN SESIÓN.
    const { document, password, checked } = payload;
    //
    try {
        // DATOS USUARIO.
        const params = {
                document: document,
                password: password,
                checked: checked
            }
            // DATOS DE SESIÓN DEL USUARIO.
        yield put(sessionDataSuccess(params));
        // GUARDAR DATOS USUARIOS LOCALSTORAGE.
        // localStorage.setItem(SESSION_DATA, JSON.stringify(params));

    } catch (error) {
        yield put(showAuthMessage(error));
    }
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* userDataUpdate() {
    yield takeEvery(USER_UPDATE, userChangeData);
}

export function* userSessionData() {
    yield takeEvery(SESSION_DATA, saveSessionData);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        fork(signOutUser),
        fork(userDataUpdate),
        fork(userSessionData)
    ]);
}
