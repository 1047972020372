import React from "react";
import Button from '@material-ui/core/Button';
import Spinner from 'react-bootstrap/Spinner';

export default class Btn extends React.Component {

    render() {
        const btnPrimaryApp = {
            backgroundColor: "#02b5ad",
            color: "white",
            width:'100%'
        };
        return (
            <span>
                {this.props.isLoggedIn ? <Button type="button" style={btnPrimaryApp} disabled><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&nbsp; Validando... </Button> : <Button type="submit" style={btnPrimaryApp} variant="contained" disabled={this.props.disabled} color="primary"> {this.props.title} </Button>}
            </span>
        )
    }
} 