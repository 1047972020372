import axios from 'axios';
import * as moment from 'moment';
import { getCookie, removeCookie } from '../util/util';
// import { JWT_RESPONSE_HEADER } from '../constants/ActionTypes';

const axiosApi = axios.create();

axiosApi.interceptors.request.use(
    async config => {
        // `headers` are custom headers to be sent
        config.headers = { 'Accept': 'application/json', 'Content-Type': 'application/json', ...config.headers };
        // VALIDAR RESPUESTA DE LA PETICIÓN.
        config.transformResponse = axios.defaults.transformResponse.concat((response) => {
            // RESPUESTA POR DEFECTO.
            return response;
        });
        config.validateStatus = (status) => {
            const expirationDate = getCookie('sessionExpiration');
            const currentDate = new Date();
            const currentPath = window.location.pathname;

            
            const VALIDATION_CURRENT_PATH = (
                currentPath !== "/" &&
                currentPath !== "/login" &&
                currentPath !== "/register" &&
                currentPath !== "/forgetPassword" &&
                currentPath !== "/recoveryPassword" &&
                currentPath !== "/onboarding" &&
                currentPath !== "/sms/validation"
            );
            if (VALIDATION_CURRENT_PATH && expirationDate) {
                const expirationDateObj = new Date(expirationDate);
                // Si la fecha de expiración es menor que la fecha actual, la sesión ha expirado
                if (currentDate > expirationDateObj) {
                    removeCookie('sessionExpiration');
                    localStorage.removeItem("LAST_REQUEST_DATE");
                    localStorage.removeItem("LOGGED_USER");
                    localStorage.removeItem("USER_ID");
                    window.location.href = "/login";
                } else {
                    // Seteo nueva fecha de última petición si la sesión sigue siendo válida
                    localStorage.setItem("LAST_REQUEST_DATE", moment(new Date()).format('DD/MM/YYYY HH:mm:ss'));
                }
            } else {
                // Seteo nueva fecha de ultima petición
                localStorage.setItem("LAST_REQUEST_DATE", moment(new Date()).format('DD/MM/YYYY HH:mm:ss'));
            }
            return status >= 200 && status < 500; // default
        };
    
        return config;
    }
)

export default axiosApi;