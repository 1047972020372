import React from "react";
// import { useHistory } from "react-router-dom";
import { useRouteMatch } from "react-router-dom";
// 
//import HeaderBar from '../Sidebar/Header';
//import Menu from '../Sidebar/Menu';
//import Header from "../Header";
// VISTAS - ROUTER.
//import Views from '../../pages/views';
import './MainPagos.scss';
import logo_jamar from '../../assets/images/pagos/logo_jamar.svg';
import Pagar from "../../pages/pagar";

const MainPagos = () => {
    // 
    const match = useRouteMatch();

    
    return (
        <div className="parapagos" style={{ width: "100%" }}>
            <div className="toolbar-position text-center" role="banner" >
                <img className="toolbar-img" width="120" alt="Jamar" src={logo_jamar} />
            </div>
            <div id="content-header" className="row justify-content-center" style={{ width: "100%" }}> 
            </div>
            <Pagar match={match} />

        </div>
    )
}

export default MainPagos;