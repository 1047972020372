import {
  HIDE_MESSAGE,
  SHOW_MESSAGE,
  SIGNIN_USER_SUCCESS,
  SIGNOUT_USER_SUCCESS,
  SIGNUP_USER_SUCCESS,
  SIGNIN_HASH_USER,
  LOGGED_USER_SESSION,
  SIGNIN_DATA_USER,
  SIGNIN_DATA_USER_SUCCESS,
  SESSION_DATA,
  SESSION_DATA_SUCCESS
} from "../../constants/ActionTypes";

let UserData = null;
// VALIDAR SI HAY DATO DE USUARIO.
if (localStorage.getItem(LOGGED_USER_SESSION)) {
  UserData = JSON.parse(localStorage.getItem(LOGGED_USER_SESSION));
}

let SessionData = {
  document: '',
  password: '',
  checked: false
};
// VALIDAR SI EL USUARIO TIENE DATOS DE USUARIO Y CONTRASEÑA.
if (localStorage.getItem(SESSION_DATA)) {
  SessionData = JSON.parse(localStorage.getItem(SESSION_DATA));
}

const INIT_STATE = {
  loader: false,
  alertMessage: '',
  showMessage: false,
  authUser: localStorage.getItem(SIGNIN_HASH_USER),
  userData: UserData,
  SessionData: SessionData
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SIGNUP_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        authUser: action.payload
      }
    }
    case SIGNIN_DATA_USER_SUCCESS: {
      return {
        ...state,
        loader: false,
        userData: action.payload
      }
    }
    case SIGNIN_DATA_USER: {
      return {
        ...state,
        loader: false,
        userData: action.payload
      }
    }
    case SESSION_DATA_SUCCESS: {
      return {
        ...state,
        loader: false,
        SessionData: action.payload
      }
    }
    case SIGNOUT_USER_SUCCESS: {
      return {
        ...state,
        authUser: null,
        initURL: '/',
        loader: false
      }
    }
    case SHOW_MESSAGE: {
      return {
        ...state,
        alertMessage: action.payload,
        showMessage: true,
        loader: false
      }
    }
    case HIDE_MESSAGE: {
      return {
        ...state,
        alertMessage: '',
        showMessage: false,
        loader: false
      }
    }
    default:
      return state;
  }
}
