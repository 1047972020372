import axios from 'axios';

export const methorTwo = async (amount , redirect,acount) => {
  try {
    const dataPay = await axios.get( 
      (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host) || window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host))
    ? 'https://m6fr15dhye.execute-api.us-east-1.amazonaws.com/prd/v1/JP/clave/url' //Prod
    : 'https://akcz699v2l.execute-api.us-east-1.amazonaws.com/dev/v1/JP/clave/url' //dev,            
      , {
      params: {
        amount: amount,
        returnUrl: redirect,
        acount: acount
      }
    });
    const response = await dataPay;
    return response;

  } catch (error) {
    console.log(error);
    return error
  }
};
export const validatePayEpayco = async (data ) => {
  try {
    const dataPay = await axios.get(
      (window.DOMINIO_JAMAR_COLOMBIA.includes(window.location.host))
//    ? 'https://5negiw991g.execute-api.us-east-1.amazonaws.com/dev/transaction/'+data //dev
//    : 'https://io96hdw1m5.execute-api.us-east-1.amazonaws.com/prd/transaction/'+data //Prod
    ? 'https://io96hdw1m5.execute-api.us-east-1.amazonaws.com/prd/transaction/'+data //dev
    : 'https://io96hdw1m5.execute-api.us-east-1.amazonaws.com/prd/transaction/'+data //Prod
      );
    const response = await dataPay;
    response.data.success=true;
    return response.data;

  } catch (error) {
    return {
      success:false
    }
  }
};


export const listMethods = [
]