// Sesion
export const JWT_RESPONSE_HEADER = 'TOKEN';
export const SIGNIN_HASH_USER = 'USER_ID';
export const LOGGED_USER_SESSION = 'LOGGED_USER';
export const SESSION_DATA = 'SESSION_DATA';
export const SESSION_DATA_SUCCESS = 'SESSION_DATA_SUCCESS';

// Compras
export const PURCHASES_DATA = 'PURCHASES_DATA';
export const WARRANTY_CASES_DATA = 'WARRANTY_CASES_DATA';
export const WARRANTY_CASES_DATA_SUCCESS = 'WARRANTY_CASES_DATA_SUCCESS';
export const PURCHASES_DATA_SUCCESS = 'PURCHASES_DATA_SUCCESS';
export const EXPANDED_PURCHASE_SUCCESS = 'EXPANDED_PURCHASE_SUCCESS';
export const DELIVERY_QUALIFICATION = 'DELIVERY_QUALIFICATION';
export const ASSEMBLY_QUALIFICATION = 'ASSEMBLY_QUALIFICATION';
export const PRODUCT_QUALIFICATION = 'PRODUCT_QUALIFICATION';
export const DELIVERY_QUALIFICATION_RESULT = 'DELIVERY_QUALIFICATION_RESULT';
export const ASSEMBLY_QUALIFICATION_RESULT = 'ASSEMBLY_QUALIFICATION_RESULT';
export const PRODUCT_QUALIFICATION_RESULT = 'PRODUCT_QUALIFICATION_RESULT';
export const GET_PURCHASE_PRODUCTS = 'GET_PURCHASE_PRODUCTS';
export const GET_WARRANTY_CASES = 'GET_WARRANTY_CASES';

// Customizer const
export const HEIGHT_CONTENT = 'HEIGHT-CONTENT';
export const HEIGHT_GLOBAL = 'HEIGHT-GLOBAL';
export const ACTIVE_ENTREGA = 'ACTIVE-ENTREGA';
export const INACTIVE_ENTREGA = 'INACTIVE-ENTREGA';
export const ACTIVE_ENTREGA_SUCCESS = 'ACTIVE-ENTREGA-SUCCESS';

//Contact Module const
export const FETCH_START = 'fetch_start';
export const FETCH_SUCCESS = 'fetch_success';
export const FETCH_ERROR = 'fetch_error';
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

export const ON_SHOW_SUBLOADER = 'ON_SHOW_SUBLOADER';
export const ON_HIDE_SUBLOADER = 'ON_HIDE_SUBLOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_DATA_USER = 'SIGNIN_DATA_USER';
export const SIGNIN_DATA_USER_SUCCESS = 'SIGNIN_DATA_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';
export const USER_UPDATE = 'USER_UPDATE';


export const USER_DATA_PAGOS = 'USER_UPDATE';

// PAGOS RECURRENTES
export const SET_STATUS_RECURRENCY_URL = 'SET_STATUS_RECURRENCY_URL';
export const SET_ENABLE_RECURRENCY_URL = 'SET_ENABLE_RECURRENCY_URL';
export const SHOW_RECURRENCY_COMPONENTS = 'SHOW_RECURRENCY_COMPONENTS';
export const RECURRENCY_STEP = 'RECURRENCY_STEP';
export const DATA_RECURRENCY_FROM_API = 'DATA_RECURRENCY_FROM_API';
