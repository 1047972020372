import React, { useState } from "react";
import { FormControl, TextField } from "@material-ui/core";
import http from "../../../../https/api";
import { sha256 } from "js-sha256";
import { makeStyles } from "@material-ui/core/styles";
import "./styles.scss";
import { useEffect } from "react";
import { encryptData } from "../../../../util/util";

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 220,
    marginBottom: 20,
  },
  passwordContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0 30px",
  },
}));

const NewPassword = ({
  password,
  setPassword,
  confirmPassword,
  setConfirmPassword,
  changePasswordToken,
  isLoading,
  loadingIcon,
  setIsLoading,
  handleSetStep,
}) => {
  const [error, setError] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setIsLoading(false);
  }, [setIsLoading]);

  const changePassword = async () => {
    if (password !== confirmPassword) {
      return setError(true);
    }

    setIsLoading(true);

    try {
      //const is_aldeamo = JSON.parse(localStorage.getItem("usingAldeamo"))
      //let response = {}
      /*if (is_aldeamo) {
        response = await http.put(`users/change-password`, {
          token_id: changePasswordToken,
          new_password: sha256(password),
          is_aldeamo
        });
        localStorage.setItem("usingAldeamo", "false")
      } else {
        response = await http.put(`users/change-password`, {
          token_id: changePasswordToken,
          new_password: sha256(password),
        });
      }*/
      let urlBase = process.env.REACT_APP_INGRESO;
      let formData = {
        id: encryptData(changePasswordToken.toString()),
        new_password: encryptData(sha256(password))
      };
      let response = await http.put(`${urlBase}users/change-password`, formData); //13

      if (response.status !== 200) {
        setError(
          "No se pudo cambiar su contraseña, intente de nuevo más tarde"
        );
      } else {
        handleSetStep();
      }
    } catch (e) {
      setError("No se pudo cambiar su contraseña, intente de nuevo más tarde");
    } finally {
      setIsLoading(false);
    }
  };

  return isLoading ? (
    loadingIcon
  ) : (
    <>
      <div className={classes.passwordContainer}>
        <FormControl className={classes.formControl}>
          <TextField
            label="Contraseña"
            value={password}
            type="password"
            error={password && confirmPassword && (password !== confirmPassword)}
            helperText={(password && confirmPassword && (password !== confirmPassword)) ? "Las contraseñas deben ser iguales." : ""}
            onChange={(event) => setPassword(event.target.value)}
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            label="Confirmar Contraseña"
            value={confirmPassword}
            type="password"
            error={password && confirmPassword && (password !== confirmPassword)}
            onChange={(event) => setConfirmPassword(event.target.value)}
          />
        </FormControl>
        {error && <div className="error-text">{error}</div>}
        {password && confirmPassword && password === confirmPassword && (
          <button className="submit-password" onClick={() => changePassword()}>
            Enviar
          </button>
        )}
      </div>
    </>
  );
};

export default NewPassword;
