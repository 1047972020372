import React, { useState } from "react";
import clsx from "clsx";
import {
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from "@material-ui/core";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import useStyles from "./styles";
import http from "../../../../https/api";
import ReceptionSelectLabel from "../../../../components/ReceptionSelectLabel";
import { useEffect } from "react";
import { encryptData } from "../../../../util/util";

function StyledRadio(props) {
  const classes = useStyles();

  return (
    <Radio
      disableRipple
      color="default"
      checkedIcon={<span className={clsx(classes.icon, classes.checkedIcon)} />}
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
}

const ReceptionSelect = ({
  isLoading,
  loadingIcon,
  setIsLoading,
  reception,
  setReception,
  documentNumber,
  setShouldShowButton,
  goBack,
}) => {
  const classes = useStyles();
  const [notFound, setNotFound] = useState();
  const [contactMethods, setContactMethods] = useState({});
  useEffect(() => {
    setReception("email");
    const fetchUserContactMethods = async () => {
      let formData = { document: encryptData(documentNumber) }
      try {
        let urlBase = process.env.REACT_APP_INGRESO;
        const response = await http.post(
          `${urlBase}users/recovery/contacts`, //10
          formData
        );
        if (response.status === 404) {
          return setNotFound(true);
        }
        setContactMethods({
          email: response.data.email,
          phone: response.data.cellphone,
        });
        setShouldShowButton(true);
      } catch (e) {
        setNotFound(true);
      } finally {
        setIsLoading(false);
      }
    };

    fetchUserContactMethods();
  }, [documentNumber, setIsLoading, setReception, setShouldShowButton]);

  return (
    <>
      {isLoading ? (
        loadingIcon
      ) : notFound ? (
        <>
          <div className={classes.helperText}>
            No se encontró ningún usuario con esta identificación.
          </div>
          <span className={classes.goBack} onClick={() => goBack()}>
            Volver
          </span>
        </>
      ) : (
        <>
          <div className={classes.helperText}>
            ¿En donde te gustaría recibir el código de veriﬁcación para
            recuperar tu contraseña?
          </div>
          <div className={classes.receptionChoices}>
            <FormControl component="fieldset" style={{ width: "100%" }}>
              <RadioGroup
                defaultValue="email"
                name="customized-radios"
                className="reception-radio-buttons-group"
                onChange={(event) => setReception(event.target.value)}
              >
                <div className="reception-label">Correo electrónico</div>
                <FormControlLabel
                  value="email"
                  control={<StyledRadio />}
                  label={
                    <ReceptionSelectLabel
                      isChecked={reception === "email"}
                      text={contactMethods.email}
                      Icon={() => <EmailIcon />}
                    />
                  }
                />
                <div className="reception-label">Celular</div>
                <FormControlLabel
                  value="phone"
                  control={<StyledRadio />}
                  label={
                    <ReceptionSelectLabel
                      isChecked={reception === "phone"}
                      text={contactMethods.phone}
                      Icon={() => <PhoneIcon />}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </div>
        </>
      )}
    </>
  );
};

export default ReceptionSelect;
