import React from "react";
import http from "../../../../../https/api";
import loadingCircle from "../../../../../assets/images/loading-circle.gif";
import { useEffect, useState } from "react";
import { encryptData, getCookie } from "../../../../../util/util";

const PeaceChoice = ({
  userData,
  setIsLoading,
  isLoading,
  setButtonFunction,
  pdfBackend,
  setShouldShowButton,
}) => {
  const [noPdf, setNoPdf] = useState(false);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      let urlBase = process.env.REACT_APP_CREDITO;
      let token = getCookie('token');
      let formData = {
        company: userData.company,
        document_name: encryptData('PazYSalvo'),
        document_data: [
          {
            'name': 'key_1',
            'value': userData.company,
            'decrypt': false
          },
          {
            'name': 'key_2',
            'value': encryptData(userData.document),
            'decrypt': true
          },
          {
            'name': 'key_3',
            'value': encryptData(userData.agency),
            'decrypt': true
          },
          {
            'name': 'key_4',
            'value': encryptData(userData.account),
            'decrypt': true
          },
          {
            'name': 'key_5',
            'value': userData.credit_type,
            'decrypt': false
          }
        ]
      }
      const pdfResponse = await http.post(
        `${urlBase}orchestrator`,   //30
        formData,
        { headers: { 'Authorization': `Bearer ${token}` } }
      );
      if (pdfResponse?.data?.documento) {
        setButtonFunction(() => {
          window.open(pdfResponse.data.documento);
        });
        setShouldShowButton(true);
      } else {
        setNoPdf(true);
      }
      setIsLoading(false);
    })();
  }, [
    setButtonFunction,
    setShouldShowButton,
    pdfBackend,
    userData,
    setIsLoading,
  ]);

  return (
    <div>
      {isLoading && (
        <div className="offset-5 col-7">
          <img className="mt-1" src={loadingCircle} alt="loadingCircle" />
        </div>
      )}
      {noPdf && (
        <span>
          Certificado no disponible para el estado actual de su cuenta
        </span>
      )}
    </div>
  );
};

export default PeaceChoice;
