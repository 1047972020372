import React from 'react';

import santaIcon from './images/icon-pagaygana.svg'; // Asegúrate de que la ruta a la imagen es correcta
let santaIcon2 =  'https://cdn.shopify.com/s/files/1/0516/5478/7271/files/icon-face-emoji.svg?v=1715028714'; // Asegúrate de que la ruta a la imagen es correcta


const RenderSantasIcon = ({ santipuntos }) => {
  let totalPuntos = santipuntos;
  let maximosPuntos = 7;

  const santas = Array.from({ length: maximosPuntos }, (_, index) => (
    <div 
    className={`icon-puntos rounded-circle d-flex flex-column justify-content-center align-items-center align-content-center 
      ${totalPuntos > index ? 'active' : 'opacity'}`
    }>
      <span className='main-text-icon'>
        {index+1}
      </span>
      <span>
        Puntos
      </span>
    </div>
    // <img
    //   width={20}
    //   key={index}
    //   src={index >= santipuntos ? santaIcon2 : santaIcon }
    //   alt="Imagen que muestrs sticker de santipuntos"
    //   className={index >= santipuntos ? 'opaco' : ''}
    // />
  ));

  return <div className='d-flex justify-content-center'>{santas}</div>;
};

export default RenderSantasIcon;