import React from "react";
import Button from '@material-ui/core/Button';
import Spinner from 'react-bootstrap/Spinner';

export default class Btn extends React.Component {

    render() {
        const btnPrimaryApp = {
            backgroundColor: "#17a2b8",
            color: "white",
            width:'100%',
            fontSize:'1rem'
        };
        return (
            <span>
                {this.props.isLoggedIn ? <Button type="button" style={btnPrimaryApp} disabled><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&nbsp; Consultando Informacion... </Button> : <Button type="submit" className="btn-info" style={btnPrimaryApp} variant="contained" disabled={this.props.disabled} color="primary"> {this.props.title} </Button>}
            </span>
        )
    }
} 