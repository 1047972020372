import React, { Component } from 'react';
import { connect } from "react-redux";
import './login.scss';
import { TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import http from '../../https/api';
import BtnSubmit from '../../components/btn_submit/indexRed';
import {
  userSignIn, sessionDataId
} from "../../appRedux/actions/Auth";
import { sha256 } from 'js-sha256';
import logoJamar from "../../assets/images/JamarLogo.svg"
import Swal from 'sweetalert2';
import $ from 'jquery';
import { encryptData, setCookie } from '../../util/util';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      formControls: {
        document: props.SessionData.document,
        password: props.SessionData.password
      },
      isLoggedIn: false,
      idMessageIn: false,
      MessageIn: '',
      showInfoIDPanama: false,
      noCacheImagen: 'https://cdn.shopify.com/s/files/1/0519/2695/8235/files/banner-portal-clientes.webp?v='+ new Date().getTime(),
      noCacheImagenPA: 'https://cdn.shopify.com/s/files/1/0516/5478/7271/files/Pop-up-portal-cliente-500x214-2024.webp?v='+ new Date().getTime()
    }
  }

  componentDidMount() {
    this.validateInfoIDPanama();
    const token = this.props.match.params.id;
    if (token) { this.validateToken(token) };
  }

  changeHandler = event => {
    this.setState({
      formControls: {
        ...this.state.formControls,
        [event.target.name]: event.target.value
      }
    });
  }

  showInfoValidationPanama(val) {
    if (val) {
      $("#info-id-panama").show(200);
    } else {
      $("#info-id-panama").hide(200);
    }
  }

  validateInfoIDPanama() {
    if (window.DOMINIO_JAMAR_PANAMA.includes(window.location.host)) {
      this.setState({ showInfoIDPanama: true });
    } else {
      this.setState({ showInfoIDPanama: false });
    }
  }

  loginIn = (event) => {
    event.preventDefault();
    // REASIGNAR ESTADO DE BTN LOANDING Y MESSAGE.
    if (this.state.formControls.document && this.state.formControls.password) {
      this.setState({ isLoggedIn: true, idMessageIn: false });
      const formControls = this.state.formControls;
      const urlBase = process.env.REACT_APP_LOGIN;
      // PARAMETROS.
      const FormData = {
        document: formControls.document,
        password: sha256(formControls.password).toString()
      }
      localStorage.setItem("deliveryModalAlreadySeen", false);
      localStorage.setItem("qualificationModalAlreadySeen", false);
      localStorage.setItem("summaryModalAlreadySeen", false);
      //ELEGIR COMPAÑÍA
      let company = "JA";
      if(window.DOMINIO_JAMAR_PANAMA.includes(window.location.host)){
        company = "JP";
      }
      // EJECUTAR PETICIÓN.
      http.post(`${urlBase}login/v1/${company}`, FormData).then(result => { //3
        // VALIDAR STATUS - OK.
        if (result.status === 200) {
          // VALIDAR SI LA PETICÓN FUE EXITOSA.
          if (result.data) {
            // VALIDAR RETIRNO DATOS DEL USUARIO.
            sessionStorage.setItem('infoUser', JSON.stringify(result.data) );
            if (result.data.account) {
              const date_experite = result.data.date_experite;
              const format_date = new Date(date_experite * 1000);
              const token = result.data.token;
              setCookie('sessionExpiration', format_date); // Cookie para manejo del tiempo en sesión
              setCookie('token', token); // Token
              this.props.userSignIn(result.data);
              // REDIRECCIONAR AL SELECT MODULE.
              return this.props.history.push(`/selectModule`);
            } else {
              this.props.userSignIn(result.data);
              return this.props.history.push(`/jamarAuth`);
            }
          }
        }
        //Valida si identifciación que ingresa, está registrada.
        if (result.status === 404) {
          // REASIGNAR MESAGE DE ERROR DE CREDENCIALES.
          this.setState({ idMessageIn: true, MessageIn: 'El usuario ingresado, no se encuentra registrado' });
        }
        // VALIDAR STATUS - DOCUMENTO REGISTRADO EN LA APP.
        if (result.status === 401) {
          // REASIGNAR MESAGE DE ERROR DE CREDENCIALES.
          this.setState({ idMessageIn: true, MessageIn: 'Usuario o/y Contraseña Incorrecta' });
        }
        // VALIDAR STATUS - USUARIO AUN NO HA VALIDADO SMS
        if (result.status === 470) {
          // VALIDAR RETIRNO DATOS DEL USUARIO.
          if (result.data.id) {
            this.props.userSignIn(result.data);
            // REDIRECCIONAR AL SMS VALIDATION.
            localStorage.setItem('DATAUSER', JSON.stringify(result.data));
            return this.props.history.push(`/securityCode`);
          }
        }
        // REASIGNAR BTN LOANDING.
        this.setState({ isLoggedIn: false });
      }, () => {
        // REASIGNAR BTN LOANDING.
        this.setState({ isLoggedIn: false });
        // REASIGNAR MESAGE DE ERROR DE CREDENCIALES.
        this.setState({ idMessageIn: true, MessageIn: 'El usuario ingresado, no se encuentra registrado' });
      });
    } else {
      this.setState({ idMessageIn: true, MessageIn: 'Debe llenar todos los campos' });
    }
  }

  register = () => {
    this.props.history.push(`/register`);
  }

  forgetPassword = () => {
    this.props.history.push(`/forgetPassword`);
  }

  /**
   * MÉTODO PARA REGISTRAR LOS DATOS DEL USUARIO.
   * @param {*} event 
   */
  validateToken = (token) => {
    // EJECUTAR PETICIÓN.
    Swal.fire({
      text: 'Por favor, espere...',
      // timer: 2000,
      allowOutsideClick: false,
      allowEscapeKey: false,
      onOpen: () => {
        Swal.showLoading();
      }
    });
    http.get('/users/validation/' + token).then(result => {
      // VALORES POR DEFECTO.
      // VALIDAR STATUS - OK.
      Swal.close();
      if (result.status === 200) {
        localStorage.setItem('DATAUSER', JSON.stringify(result.data));
        this.props.history.push(`/confirmPassword`);
      }
      if (result.status === 404) {
        console.log("El token no existe (redirigir a login)");
      }
      if (result.status === 405) {
        console.log("El token existe pero el usuario ya se registró (redirigir a login)");
      }
    }).catch((e) => {
      // REASIGNAR MESAGE DE ERROR DE CREDENCIALES.
    })
  }

  render() {
    return (
      <div className="row conten-login">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 envoltura">
          <div className="row">
            <div className="card col-lg-12 col-md-12 col-sm-12 col-12 card-banner">
              <div className="row justify-content-center">
                <img
                  src={logoJamar}
                  className="col-lg-2 col-md-2 col-sm-3 col-6 mb-4"
                  alt="Jamar logo"
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-7 col-11">
                  <span className="tittle d-block text-center">¡Bienvenido al portal de clientes!</span>
                  <span className="text d-block text-center">Tenemos muchos beneficios para ti. Consulta tus compras, entregas, armados, cuenta, movimientos y paga tu factura de manera rápida y segura.</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row justify-content-center container-login-with-banner">
          { !this.state.showInfoIDPanama ? 
          <div className="row portal-banner justify-content-center">
              <a  href='https://bit.ly/49joET7' className="row justify-content-center col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 p-0 p-sm-0">
                <img
                  src={this.state.noCacheImagen}
                  className="img-fluid"
                  alt="Banner del portal"
                />
              </a>
          </div>
          :
          <div className="row portal-banner justify-content-center">
              <a  href='https://jamar.com.pa/portal/banner' className="row justify-content-center col-xl-4 col-lg-5 col-md-6 col-sm-8 col-12 p-0 p-sm-0">
                <img
                  src={this.state.noCacheImagenPA}
                  className="img-fluid"
                  alt="Banner del portal"
                />
              </a>
          </div>
          }
            <div className="card col-xl-4 col-lg-5 col-md-6 col-sm-8 col-10 card-form position-static">
              <form onSubmit={this.loginIn}>
                <div className="col-12">
                  <div className="row mb-2 options">
                    <span className="col-6 text-center">INICIAR SESIÓN</span>
                    <span className="col-6 text-center" onClick={this.register}>REGISTRARSE</span>
                  </div>
                </div>
                {/* MESSAGE DE ERROR O CREDENCIALES INVALIDAS */}
                {this.state.idMessageIn && <div className="form-group"><Alert severity="error">{this.state.MessageIn}</Alert></div>}
                {/* INPUT */}
                <div className="form-group">
                  <TextField type="text" className={`col-lg-12 ${this.state.idMessageIn ? 'input_error' : ''}`} onFocus={() => this.showInfoValidationPanama(true)} onBlur={() => this.showInfoValidationPanama(false)} name="document" value={this.state.formControls.document} onChange={this.changeHandler} label="Número de identificación" required />
                  {
                    (this.state.showInfoIDPanama) && <ul id="info-id-panama" style={{ display: "none" }}>
                      <li>Si tu documento empieza con cero (0) <b>no</b> incluirlo.</li>
                      <li>Si tu documento de identidad incluye guiones (-) <b>debes</b> ingresarlo.</li>
                      <li>Si tu documento de identidad incluye letras <b>debes</b> incluirlas.</li>
                    </ul>
                  }
                </div>
                <div className="form-group">
                  <TextField type="password" className="col-lg-12" name="password" value={this.state.formControls.password} onChange={this.changeHandler} label="Contraseña" required />
                </div>
                <div className="col-12 check-forgetpass">
                    <p className="col-7 col-md-9 col-sm-8 mr-0" id="forgot-pass-login" onClick={this.forgetPassword}>¿Olvidó su contraseña?</p>
                </div>
                {/* <div className="cont-buttons"> */}
                <div className="row justify-content-center">
                  <div className="content-btn-log btn-content mb-3">
                    <BtnSubmit title="Iniciar sesión" isLoggedIn={this.state.isLoggedIn}></BtnSubmit>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser, SessionData } = auth;
  return { authUser, SessionData }
};

export default connect(mapStateToProps, {
  userSignIn,
  sessionDataId
})(Login);