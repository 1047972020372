import React, { Component } from 'react';
import { Button } from '@material-ui/core';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import './menuJamar.scss';

class MenuJamar extends Component {

  redirect = (pageToRedirect) => {
    if (pageToRedirect) this.props.history.push('/jamarAuth/' + pageToRedirect);
  }

  /**
   * Establece si se debe activar el tab o no
   */
  activateTab(pathPage, isLoadFirst) {
    let basePath = "/jamarAuth";
    let path = basePath + pathPage;
    const { location } = this.props;
    let pathname = (location && location.pathname) ? location.pathname : null;
    if (pathname === path) {
      return true;
    } else if (isLoadFirst && pathname === basePath) {
      return true;
    } else {
      return false;
    }
  }

  render() {
    return (
      <div className="justify-content-center row" id="content-menu-jamar">
        <div className="main-manu">
          <div className="row content-menu-jamar">
            <Button type="button" className={`btn btn-tabs-menu ${this.props.activeEntrega ? '' : this.activateTab('/warranty', true) ? 'activate-btn' : ''}`} variant="outlined" onClick={() => this.redirect('warranty')}>Compras</Button>

            <Button type="button" className={`btn btn-tabs-menu ${this.props.activeEntrega ? 'activate-btn' : ''} ${this.activateTab('/delivery') ? 'activate-btn' : ''}`} variant="outlined" onClick={() => this.redirect('delivery')}>Entregas</Button>

            <Button type="button" className={`btn btn-tabs-menu ${this.activateTab('/assembly') ? 'activate-btn' : ''}`} variant="outlined" onClick={() => this.redirect('assembly')}>Armados</Button>

            <Button type="button" className={`btn btn-tabs-menu ${this.activateTab('/warrantyCases') ? 'activate-btn' : ''}`} variant="outlined" onClick={() => this.redirect('warrantyCases')}>Garantías</Button>

            {/* <Button type="button" className={`btn btn-tabs-menu ${this.activateTab('/purchases') ? 'activate-btn' : ''}`} variant="outlined" onClick={() => this.redirect('purchases')}>Compras1</Button> */}
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = ({ settings }) => {
  const { activeEntrega } = settings;
  return { activeEntrega }
};

export default connect(mapStateToProps, {})(withRouter(MenuJamar));