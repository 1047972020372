import React from 'react';import { useSelector } from "react-redux";
import './header.scss';
import userImageExampleBlue from "../../../assets/images/user/Avatar-04.png";
import userImageExampleRed from "../../../assets/images/user/avatar-rojo.png";
import { withRouter, useLocation } from 'react-router-dom';

const Greeting = ({ message, image }) => {
  return (
    <div className="greeting-container">
      <div className="user-picture">
        <div className="profile-photo" style={{ backgroundImage: `url(${image})` }}></div>
      </div>
      <div className="user-greeting">
        {message.pay ? <span>Hola, </span> : null}
        {message.message}
      </div>
    </div>
  );
}

const Account = ({ accountId }) => {
  // 
  const location = useLocation();
  // VALIDAR LA RUTA A REDIRECCIONAR.
  const valid = (location.pathname.indexOf('jamarAuth/profile') > -1);
  return valid ? <span></span> : <div className="account-container">Cuenta {accountId}</div>;
}

const HeaderBar = () => {
  // DATOS DEL USUARIO EN SESIÓN.
  const { userData } = useSelector(({ auth }) => auth);
  const location = useLocation();

  /**
   * Escoje que componente debe mostrar de acuerdo a la ruta actual
   */
  const textToShow = () => {
    let varReturn = `aquí podrás consultar y pagar tu factura.`;

    let pathname = (location && location.pathname) ? location.pathname : null;
    let pay = false;
    if (pathname) {
      switch (pathname) {
        case '/auth':
        case '/auth/pay':
          varReturn = `aquí podrás consultar y pagar tu factura.`;
          pay = true;
          break;
        case '/auth/purchases':
          varReturn = 'Mira el avance de tus pagos por cada compra.';
          break;
        case '/auth/my-quota':
          varReturn = 'Aquí podrás ver tu cupo aprobado y cupo disponible.';
          break;
        case '/auth/movements':
          varReturn = 'Tus últimos movimientos organizados por fecha.';
          break;
        case '/auth/extracts':
          varReturn = 'Descarga tus documentos aquí, en el rango de fechas que desees.';
          break;
        case '/jamarAuth':
        case '/jamarAuth/warranty':
          varReturn = 'Hola, aquí podrás encontrar información de todas tus compras.';
          break;
        case '/jamarAuth/delivery':
          varReturn = 'Aquí podrás conocer el estado de tus entregas.';
          break;
        case '/jamarAuth/assembly':
          varReturn = 'Aquí podrás hacerle seguimiento a tus citas de armado.';
          break;
        case '/jamarAuth/profile':
          varReturn = 'Aquí podrás revisar y actualizar tus datos.';
          break;
        case '/jamarAuth/warrantyCases':
          varReturn = 'Aquí podrás conocer el estado de tus solicitudes de garantía.';
          break;
        default:
          varReturn = `aquí podrás consultar y pagar tu factura.`;
          pay = true
          break;
      }
    }

    return { message: varReturn, pay };
  }

  const userImageExample = location.pathname.includes("/jamarAuth") ? userImageExampleRed : userImageExampleBlue;

  return (
    <div id="content-hbar">
      <Greeting
        message={textToShow()}
        image={userData.image || userImageExample}
      />
      {
        userData.account && <Account accountId={userData.account} />
      }
    </div>
  );
}

export default withRouter(HeaderBar);