import React, { Component } from 'react';
import { connect } from "react-redux";
import './CodeSecurity.scss';
import { TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import http from '../../https/api';
import BtnSubmit from '../../components/btn_submit/indexRed';
import {
  userSignIn, sessionDataId
} from "../../appRedux/actions/Auth";
import logoJamar from "../../assets/images/jamar.png"
import { encryptData } from '../../util/util';

class securityCode extends Component {

  constructor(props) {
    super(props);
    // OBTENER DATOS USUARIOS EN SESIÓN.
    let { userData } = props;
    this.state = {
      formControls: {
        code: ''
      },
      isLoggedIn: false,
      idMessageIn: false,
      MessageIn: '',
      typeMessage: 'success',
      intdisabled: true,
      btnResend: false,
      emailToShow: (userData) ? userData.email : '',
      cellphoneToShow: (userData) ? userData.cellphone : '',
      user: userData
    }
  }

  changeHandler = event => {
    this.setState({
      formControls: {
        ...this.state.formControls,
        [event.target.name]: event.target.value
      }
    });
  }

  /*  sendOtpWithAldeamo = async (user) => {
      try {
        if (user) {
          if (user.company == "JA") {
            if (user.cellphone) {
  
              console.log(user.company, user.cellphone)
              const responseAldeamo = await http.post(`https://gm36tm91y3.execute-api.us-east-1.amazonaws.com/Stage/v1/JA/aldeamos/otp`,
                {
                  mobile: user.cellphone ? user.cellphone : "Usuario sin celular",
                  email: user.email ? user.email : "Usuario sin correo",
                  otpid: user.document ? user.document : "Usuario sin documento"
                }
              );
              if (responseAldeamo.data.errorcode == 0) {
                //Mensaje enviado
                return true
              }
              //Mensaje no enviado
            } else {
              this.setState({
                idMessageIn: true,
                MessageIn: 'Este usuario no tiene celular (aldeamo).'
              });
            }
          } else {
            this.setState({
              idMessageIn: true,
              MessageIn: 'Este usuario no es de colombia (aldeamo).'
            });
          }
          //No tiene teléfono o no es JA
        } else {
          this.setState({
            idMessageIn: true,
            MessageIn: 'Este usuario está vacío (aldeamo).'
          });
        }
        //No vino el usuario
        return false
      } catch (e) {
        console.error(e)
        this.setState({
          idMessageIn: true,
          MessageIn: 'Ocurrió un error y no pudimos enviar el mensaje (aldeamo).'
        });
      }
    }
  
    verifyCodeAldeamo = async (user, code) => {
      const response = await http.get(
        `https://gm36tm91y3.execute-api.us-east-1.amazonaws.com/Stage/v1/JA/aldeamos/validateOtp?otpId=${user.document}&pin=${code}`
      );
      if (response.data.message == "false") {
        //El mensaje no se envió
        return false
      } else {
        //El mensaje se envió
        return true
      }
    }*/
  /**
   * MÉTODO PARA ENVIAR CÓDIGO.
   */
  sendCode = async (btn = true) => {
    // 
    this.setState({ intdisabled: true, btnResend: true });
    let urlBase = process.env.REACT_APP_BASE_PORTAL_BACKEND;
    let user_id = this.state.user.id;
    let formData = {
      company: this.state.user.company,
      user_id: encryptData(user_id.toString())
    };
    console.log('form: ', formData);
    http.post(`${urlBase}users/security_code`, formData).then(result => {    //10
      // VALIDAR SI LA PETICÓN FUE EXITOSA.
      if (result.status === 201 || result.status === 200) {
        this.setState({ intdisabled: false, btnResend: false });
        // VALIDAR SI SE ACTIVA LA FUNCIÓN DESDE EL BUTTON.
        if (btn) {
          this.setState({
            idMessageIn: true,
            MessageIn: 'Se ha enviado un nuevo código a su celular.'
          });
        }
      }
    });

  }
  /**
     * MÉTODO PARA REGISTRAR LOS DATOS DEL USUARIO.
     * @param {*} event 
    */
  loginIn = async (event) => {
    event.preventDefault();
    this.setState({ isLoggedIn: true });
    let urlBase = process.env.REACT_APP_BASE_PORTAL_BACKEND;
    let user_id = (this.props.userData.id).toString();
    let formData = {
        company: this.props.userData.company,
        user_id: encryptData(user_id),
        code: encryptData(this.state.formControls.code)
    };
    localStorage.setItem("usingAldeamoRegister", "false")
    // EJECUTAR PETICIÓN.
    http.post(`${urlBase}users/securitycode`, formData).then(result => {    //11
      // VALORES POR DEFECTO.
      let params = { show: true, message: '', typeMessage: 'info' }
      // VALIDAR STATUS - OK.
      if (result.status === 200 || result.status === 201) {
        // MESAJE PARA EL USUARIO.
        params.show = false;
        // REDIRECCIONAR
        this.props.history.push(`/onboarding`);
      }
      // VALIDAR STATUS - EL USUARIO NO EXISTE.
      if (result.status === 404) {
        // MESAJE PARA EL USUARIO.
        params.show = true;
        params.message = 'El usuario no está registrado en la plataforma.';
      }

      if (result.status === 400) {
        // MESAJE PARA EL USUARIO.
        params.show = true;
        params.message = 'El codigo no coincide o expiró.';
      }

      // VALIDAR STATUS - LA COMPAÑIA DEL USUARIO ES INCORRECTA.
      if (result.status === 455) {
        // MESAJE PARA EL USUARIO.
        params.show = true;
        params.message = 'La compañía a la que pertenece el usuario no exste registrada en la plataforma.';
      }
      // VALIDAR STATUS - NO SE HA GENERADO NINGÚN CODIGO PARA EL USUARIO.
      if (result.status === 460) {
        // MESAJE PARA EL USUARIO.
        params.typeMessage = 'error';
        params.message = 'No se ha generado un código para el usuario.';
      }
      // VALIDAR STATUS - EL CÓDIGO YA FUE VALIDADO CON EXITO.
      if (result.status === 461) {
        // MESAJE PARA EL USUARIO.
        params.typeMessage = 'info';
        params.message = 'El código ya fue validado exitosamente en la plataforma.';
      }
      // VALIDAR STATUS - EL CÓDIGO YA EXPIRÓ.
      if (result.status === 462) {
        // MESAJE PARA EL USUARIO.
        params.typeMessage = 'warning';
        params.message = 'Por favor, de click en la opción de "Envíenme otro SMS".';
      }
      // VALIDAR STATUS - EL CÓDIGO NO COINCIDE.
      if (result.status === 463) {
        // MESAJE PARA EL USUARIO.
        params.typeMessage = 'warning';
        params.message = 'El código ingresado no coincide';
      }
      this.setState({
        idMessageIn: params.show,
        MessageIn: params.message,
        typeMessage: params.typeMessage,
        intdisabled: true,
        isLoggedIn: false
      });
    }).catch((e) => {
      // REASIGNAR MESAGE DE ERROR DE CREDENCIALES.
      this.setState({
        idMessageIn: true,
        MessageIn: 'Lo sentimos, ha ocurrido un error en el sistema.',
        typeMessage: 'error',
        isLoggedIn: false
      });
    })
  }

  hideInfoUser = (infoToHide, info) => {
    if (infoToHide === "email") {
      let email = info;
      let emailSplit = email.split("@");
      let part1 = emailSplit[0];
      let part2 = emailSplit[1];
      let newInitialPart1 = part1.substr(0, 2);
      let newPart1 = "";
      for (let i = 0; i < part1.length - 2; i++) {
        newPart1 = newPart1 + "*";
      }
      newPart1 = newInitialPart1 + newPart1;
      return newPart1 + "@" + part2;
    } else {
      let phone = info;
      let part2 = phone.substr(phone.length - 4, 4);
      let newPart1 = "";
      for (let i = 0; i < phone.length - 4; i++) {
        newPart1 = newPart1 + "*";
      }
      return newPart1 + part2;
    }
  }

  componentDidMount() {
    // 
    if (this.state.user) {
      // 
      setTimeout(async () => {
        await this.sendCode(false);
      }, 300);
    } else {
      // REDIRECCIONAR
      this.props.history.push(`/register`);
    }
  }

  render() {
    return (
      <div className="row conten-login">
        <div className="col-lg-12 col-md-12 col-sm-12 col-12 envoltura">
          <div className="row">
            <div className="card col-lg-12 col-md-12 col-sm-12 col-12 card-banner-code">
              <div className="row justify-content-center">
                <img
                  src={logoJamar}
                  className="col-lg-2 col-md-2 col-sm-3 col-6 mb-4"
                  alt="Jamar logo"
                />
              </div>
              <div className="row justify-content-center">
                <div className="col-lg-5 col-md-6 col-sm-7 col-10">
                  {
                    (this.state.cellphoneToShow) ?
                      <span className="text d-block text-center info-security-code">Hemos enviado un código de seguridad a tu celular {this.hideInfoUser('cell', this.state.cellphoneToShow)} y al correo {this.hideInfoUser('email', this.state.emailToShow)} para activar tu registro.</span> :
                      <span className="text d-block text-center info-security-code">Hemos enviado un código de seguridad a tu celular para activar tu registro.</span>
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="card col-xl-3 col-lg-4 col-md-6 col-sm-8 col-8 card-form-code">
              <form onSubmit={this.loginIn}>
                <div className="col-12">
                  <div className="row mb-2 options">
                    <span className="col-12 text-center">ingrese el Código de seguridad</span>
                  </div>
                </div>
                {/* MESSAGE DE ERROR O CREDENCIALES INVALIDAS */}
                {this.state.idMessageIn ? <div className="form-group"><Alert severity="error">{this.state.MessageIn}</Alert></div> : null}
                {/* INPUT */}
                <div className="form-group">
                  <TextField type="password" className={`col-lg-12 ${this.state.idMessageIn ? 'input_error' : ''}`} name="code" value={this.state.formControls.code} onChange={this.changeHandler} label="Código" required />
                  <span id="sendCode" onClick={this.sendCode} disabled={this.state.btnResend}>Enviar otro código</span>
                </div>
                {/* <div className="cont-buttons"> */}
                <div className="center-log">
                  <div className="content-btn-log btn-content mb-3">
                    <BtnSubmit title="Ingresar" isLoggedIn={this.state.isLoggedIn}></BtnSubmit>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ auth }) => {
  const { authUser, SessionData, userData } = auth;
  return { authUser, SessionData, userData }
};

export default connect(mapStateToProps, {
  userSignIn,
  sessionDataId
})(securityCode);
