import { USER_DATA_PAGOS } from "../../constants/ActionTypes";

let INITIAL_STATE = {};

const PAGOSDATA = (state = INITIAL_STATE, action) => {
  switch (action.type) {
      case USER_DATA_PAGOS:
        return {
          ...action.payload,
        };
      default:
          return state;
  }
};

export default PAGOSDATA;