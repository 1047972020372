import { HEIGHT_GLOBAL, HEIGHT_CONTENT, ACTIVE_ENTREGA } from "../../constants/ActionTypes";

export function setHeightGlobal(height) {
    return (dispatch) => {
        dispatch({ type: HEIGHT_GLOBAL, height });
    }
}

export function setHeightContent(height) {
    return (dispatch) => {
        dispatch({ type: HEIGHT_CONTENT, height });
    }
}

export function activeMenuEntrega(active) {
    return (dispatch) => {
        dispatch({ type: ACTIVE_ENTREGA, active });
    }
}