import React, {  useState } from 'react';

import './consultar.scss';
import Alert from '@material-ui/lab/Alert';
import httpPanama from '../../../https/apiPayPanama';

import icono_1 from '../../../assets/images/pagos/icono_1.png';
import icono2 from '../../../assets/images/pagos/icono2.png';
import icono_3 from '../../../assets/images/pagos/icono_3.png';
import logo_jamar_pa from '../../../assets/images/pagos/logo_jamar_pa.png';
import logo_jamar from '../../../assets/images/pagos/logo_jamar.png';
import logo_ccb from '../../../assets/images/pagos/logo_ccb.jpg';
import logo_epayco from '../../../assets/images/pagos/logo_epayco.png';
import BtnSubmit from '../../../components/btn_submit/indexPay';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { changePagos } from '../../../appRedux/actions/pagos.action';

const qs = require('querystring')


const PayUserData = (props) => {
  // DATOS DEL USUARIO EN SESIÓN.
  let [user, setUser] = useState({
    idMessageIn: false,
    MessageIn: 'Lo sentimos, no se pudo completar la transacción. Intente mas tarde.',
    typeMessage: 'error',
    loadingPay: false,
    n_ide:''
  });
  let history = useHistory();

  const isColombia=window.location.pathname === '/pagos/colombia';
  const buscarCedula = (event) => {
    event.preventDefault();
    setUser({ loadingPay: true });

    //
    // DATOS DEL FORMULARIO
    // EJECUTAR PETICIÓN.
    let c_emp = isColombia?'JA':'JP'
    let urlBase = process.env.REACT_APP_BASE_RECAUDO;
    fetch(`${urlBase}infobynide/${user.n_ide}/${c_emp}`, {
      method: 'GET',
      })
      .then(response => response.json())
      .then(data => {
            let params = { show: true, message: '', typeMessage: 'info' }
      if (data.Success) {
        data.n_ide=user.n_ide;
        props.actionPagos(data);
        params.show = false;
        
          history.push({
            pathname: isColombia?'/pagos/metodo':'/pagos/select-method'
          })
            
        
       } else {
        params.show = true;
        params.message ='Lo sentimos, No pudimos encontrar informacion de este cliente.';
      }
      setUser({
        idMessageIn: params.show,
        MessageIn: params.message,
        typeMessage: params.typeMessage,
        loadingPay: false
      });
    }, () => {
      setUser({
        idMessageIn: true,
        MessageIn: 'Lo sentimos, no se pudo completar la transacción. Intente mas tarde.',
        typeMessage: 'error',
        loadingPay: false
      });
    });

  }

  /**
 * MÉTODO GUARDAR LOS CAMBIO DE LOS CAMPOS DEL FORMULARIO.
 * @param {*} event
*/
  const changeHandler = (event) => {
    
    setUser({
      n_ide: event.target.value
    });
  }

  return (
    <div className="max-960" >
      <div className="container" >

        <div className="row justify-content-between">
          <div className="col-md-7 izquierdo d-none d-lg-block d-xl-block d-md-block"  >
            <div className="row justify-content-md-center">

              <div className="col-sm-12 estamos-aqui-para-tu">
                Estamos aquí para tu facilidad
              </div>
              <div className="col-sm-12 text-center text-white">
                En <a href={isColombia?'https://jamar.com':'https://jamar.com.pa'} className="text-red">{isColombia?'jamar.com':'jamar.com.pa'}</a> estarán disponibles tus
                pagos pendientes, solo debes ingresar tu número de identificación para conocer tu deuda y
                realizar el pago.
              </div>

              <div className="col-sm-12 mt-3">
                <div className="row">


                  <div className="col-sm-4 text-center">
                    <img src={icono_1} alt="pago en linea" />
                    <label className="text-white mt-2">
                      Pago en línea
                    </label>
                  </div>
                  <div className="col-sm-4 text-center" >
                    <img src={icono2} alt="Paga con tarjeta de credito" />
                    <label className="text-white mt-2">
                      Paga con tarjeta de crédito
                    </label>
                  </div>
                  <div className="col-sm-4 text-center">
                    <img src={icono_3} alt="Olvidate de pagar en el banco" />
                    <label className="text-white mt-2">
                      Olvídate de pagar en el banco
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-4 contendor_cedula">
            <form onSubmit={buscarCedula}>

              <div className="row justify-content-md-center">
                <div className="col-10 center text-center img_panama_jamar">
                  <img src={isColombia?logo_jamar:logo_jamar_pa} alt="JAMAR" />
                  <div className="pagos-en-linea">
                    Pagos en línea
                  </div>
                </div>

                <hr width="90%" color="#EDECEC" size="7" />
                <div className="col-12">
                  {/* MESSAGE DE ERROR O CREDENCIALES INVALIDAS */}
                  {user.idMessageIn ? <div className="form-group"><Alert severity={user.typeMessage}>{user.MessageIn}</Alert></div> : null}
                </div>


                <div className="col-sm-12 identifica">
                  <label>
                    Número de identificación
                  </label>
                </div>


                <div className="col-sm-12">
                  <div className="form-group">
                    <input type="text" className="form-control" id="IDENT"
                      aria-describedby="Cedula del titular de la cuenta" placeholder={isColombia?"11222334455":"8-0-0000"} value={user.n_ide} onChange={changeHandler} />
                  </div>
                </div>
                <div className="col-12 text-center" >
                  <BtnSubmit title="Consultar" isLoggedIn={user.loadingPay} ></BtnSubmit>
                </div>
                <hr width="90%" color="#EDECEC" size="7" />
                <div className="text-center">
                  <label>
                    <strong>
                      Aceptamos tarjetas de todos los bancos
                    </strong>
                  </label>
                </div>

                <div className="col-12 center text-center">
                  <img src={isColombia?logo_epayco:logo_ccb} width="100%" alt="CREDICORBANK" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}


const Consultar = (props) => {

  return (
    <div className="contentpagos" role="main" >
      <PayUserData 
        actionPagos={props.changePagos}
      />
    </div>
  );
  
};

const mapStateToProps = state => state;
const mapDispatchToProps = {
  changePagos
}

export default connect(mapStateToProps, mapDispatchToProps)(Consultar);