import { 
  SET_STATUS_RECURRENCY_URL, 
  SHOW_RECURRENCY_COMPONENTS,
  RECURRENCY_STEP,
  DATA_RECURRENCY_FROM_API
} from "../../constants/ActionTypes";

// reducer con initial state
const initialState = {
    recurrencia: 'https://wr1nduc0wa.execute-api.us-east-1.amazonaws.com/prd/endpoint',
    recurrencia_estado: {},
    recurrencia_pago: {},
    showRecurrency: {},
    recurrencyStep: 0,
    dataPayment: {}
}

// creando el reducer
export default function updateRecurrencyState (state = initialState, action) {
    switch (action.type) {
      case SET_STATUS_RECURRENCY_URL:
        return {
          ...state,
          recurrencia_estado: action.payload
        }
      case SHOW_RECURRENCY_COMPONENTS:
        return {
          ...state,
          showRecurrency: action.payload
        }
      case RECURRENCY_STEP:
        return {
          ...state,
          recurrencyStep: action.payload
        }
      case DATA_RECURRENCY_FROM_API:
        return {
          ...state,
          dataPayment: action.payload
        }
      default:
        return state;
    }
  }
  