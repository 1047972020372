import React, { Component } from 'react';
import { Button, Icon } from '@material-ui/core';
import { withRouter } from 'react-router-dom';
import imgPage1 from "../../assets/images/onboarding/onboarding1.png";
import imgPage2 from "../../assets/images/onboarding/onboarding2.png";
import imgPage3 from "../../assets/images/onboarding/onboarding3.png";
import imgPage4 from "../../assets/images/onboarding/onboarding4.png";
import './onboarding.scss';
import { connect } from 'react-redux';

import logoJamar from '../../assets/images/JamarLogo.svg';
import logoCrediJamar from '../../assets/images/logo-credijamar.svg';
import { LOGGED_USER_SESSION } from '../../constants/ActionTypes';

class Onboarding extends Component {

    constructor(props) {
        super(props);
        this.state = {
            currentPage: 1
        }
    }

    goToOnboard = (goTo) => {
        if (goTo === "back") {
            this.setState({
                currentPage: this.state.currentPage - 1
            });
        } else {
            this.setState({
                currentPage: this.state.currentPage + 1
            });
        }
    }

    goToHome = () => {
        let _userData = JSON.parse(localStorage.getItem(LOGGED_USER_SESSION));
        if (_userData && _userData.account) {
            this.props.history.push('/selectModule');
            this.props.history.push('/auth');
        } else {
            this.props.history.push(`/jamarAuth`);
            this.props.history.push(`/auth`);
        }
    }

    render() {
        let PAGE1 =
            <div className="content-img-onboarding">
                <img className="content-img-onboarding-step" src={imgPage1} alt="Onboarding 2" />
                <div className="content-text">
                    <h6 className="title-page-onboarding text-center">REVISA TUS COMPRAS</h6>
                    <p className="description-page-onboarding text-center">Aquí puedes revisar todas tus compras y sus productos.</p>
                </div>
            </div>;
        let PAGE2 =
            <div className="content-img-onboarding">
                <img className="content-img-onboarding-step" src={imgPage2} alt="Onboarding 1" />
                <div className="content-text">
                    <h6 className="title-page-onboarding text-center">GESTIONA TU SERVICIOS</h6>
                    <p className="description-page-onboarding text-center">Agenda servicios de transporte y armado, as&iacute; como tu garant&iacute;a</p>
                </div>
            </div>;
        let PAGE3 =
            <div className="content-img-onboarding">
                <img className="content-img-onboarding-step" src={imgPage3} alt="Onboarding 3" />
                <div className="content-text">
                    <h6 className="title-page-onboarding text-center">ADMINISTRA TU CUENTA</h6>
                    <p className="description-page-onboarding text-center">Aquí puedes revisar todos los detalles de tu CrédiJamar, desde tu estado de cuenta, facturas detallas y mucho mas.</p>
                </div>
            </div>;
        let PAGE4 =
            <div className="content-img-onboarding">
                <img className="content-img-onboarding-step" src={imgPage4} alt="Onboarding 4" />
                <div className="content-text">
                    <h6 className="title-page-onboarding text-center">CONOCE TU CUPO DISPONIBLE</h6>
                    <p className="description-page-onboarding text-center">Utilízalo en tus próximas compras!</p>
                </div>
            </div>;

        let classNameOnboarding = this.state.currentPage < 3 ? "onboarding-jamar" : "onboarding-credijamar";
        let classNameBtn = this.state.currentPage < 3 ? "btn-jamar" : "btn-credijamar";
        let logoEmpresa = <img 
            alt={this.state.currentPage < 3 ? "logo jamar" : "logo credijamar"}
            className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 p-0"
            src={this.state.currentPage < 3 ? logoJamar : logoCrediJamar}
            style={{ height: this.state.currentPage < 3 ? 26 : 30 }}
        />;

        return (
            <div className="row conten-login">
                <div className="col-lg-12 col-md-12 col-sm-12 col-12 envoltura">
                    <div className="row">
                        <div className={"card col-lg-12 col-md-12 col-sm-12 col-12 card-banner-onboarding " + classNameOnboarding}>
                            <div className="row justify-content-center">
                                <div className="header-onboarding">
                                    <div className="row justify-content-between align-items-center">
                                        <div className="p-0" style={{position: "relative", left: 10}}>
                                            {
                                                (this.state.currentPage > 1) ? <Button style={{ color: "#fff" }} type="button" className="justify-content-start p-0" onClick={() => this.goToOnboard('back')}> <Icon>keyboard_arrow_left</Icon></Button> : <Button style={{ color: "#fff" }} type="button" className="justify-content-start p-0">&nbsp;</Button>
                                            }
                                        </div>
                                        {logoEmpresa}
                                        <div className="p-0">
                                            <Button type="button" className="btn btn-secondary-app btn-skip" variant="outlined" onClick={() => this.goToHome()}> Saltar </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center ">
                        <div className="card col-xl-3 col-lg-3 col-md-4 col-sm-5 col-9 card-form-onboarding p-0">
                            <div className="justify-content-center row main-onboarding">
                                <div className={"col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center "}>
                                    {
                                        (this.state.currentPage === 1) ? PAGE1 : null
                                    }
                                    {
                                        (this.state.currentPage === 2) ? PAGE2 : null
                                    }
                                    {
                                        (this.state.currentPage === 3) ? PAGE3 : null
                                    }
                                    {
                                        (this.state.currentPage === 4) ? PAGE4 : null
                                    }
                                </div>
                                <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center content-next">
                                    <div className="row justify-content-center">
                                        <div className="col-lg-8 btn-content">
                                            {
                                                (this.state.currentPage < 4) ? <Button type="button" className={"btn btn-secondary-app btn-next " + classNameBtn} variant="outlined" onClick={() => this.goToOnboard('next')}>Siguiente</Button> : <Button type="button" className={"btn btn-secondary-app btn-next " + classNameBtn} variant="outlined" onClick={() => this.goToHome()}> Iniciar </Button>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = ({ settings }) => {
    const { heightGlobal } = settings;
    return { heightGlobal }
};

export default connect(mapStateToProps, {})(withRouter(Onboarding));
