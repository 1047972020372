import {
  SET_STATUS_RECURRENCY_URL, 
  SHOW_RECURRENCY_COMPONENTS,
  RECURRENCY_STEP,
  DATA_RECURRENCY_FROM_API
} from '../../constants/ActionTypes';

export const setRecurrencyDataState = (data) => {
        return {
          type: SET_STATUS_RECURRENCY_URL,
          payload: data
        }
}
export const showRecurrencyComponents = (data) => {
        return {
          type: SHOW_RECURRENCY_COMPONENTS,
          payload: data
        }
}
export const setRecurrencyStep = (data) => {
        return {
          type: RECURRENCY_STEP,
          payload: data
        }
}
export const setDataRecurrencyApi = (data) => {
        return {
          type: DATA_RECURRENCY_FROM_API,
          payload: data
        }
}



