import {
    PURCHASES_DATA_SUCCESS,
    WARRANTY_CASES_DATA_SUCCESS,
    ON_SHOW_LOADER,
    ON_HIDE_LOADER,
    EXPANDED_PURCHASE_SUCCESS,
    DELIVERY_QUALIFICATION_RESULT,
    ASSEMBLY_QUALIFICATION_RESULT,
    PRODUCT_QUALIFICATION_RESULT,
    ON_SHOW_SUBLOADER,
    ON_HIDE_SUBLOADER,
} from "../../constants/ActionTypes";

const INIT_STATE = {
    loader: true,
    subloader: false,
    expandedPurchase: null,
    warrantyCasesData: [],
    purchasesData: [],
    lvrQualResult: {
        showAlert: false,
        message: null,
        loader: false
    },
    asbQualResult: {
        showAlert: false,
        message: null,
        loader: false
    },
    prodQualResult: {
        showAlert: false,
        message: null,
        loader: false
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case PURCHASES_DATA_SUCCESS:
            {
                return {
                    ...state,
                    purchasesData: action.payload
                }
            }
        case WARRANTY_CASES_DATA_SUCCESS:
            {
                return {
                    ...state,
                    warrantyCasesData: action.payload
                }
            }
        case EXPANDED_PURCHASE_SUCCESS:
            {
                return {
                    ...state,
                    expandedPurchase: action.payload
                }
            }
        case ON_SHOW_LOADER:
            {
                return {
                    ...state,
                    loader: true
                }
            }
        case ON_HIDE_LOADER:
            {
                return {
                    ...state,
                    loader: false
                }
            }
        case ON_SHOW_SUBLOADER:
            {
                return {
                    ...state,
                    subloader: true
                }
            }
        case ON_HIDE_SUBLOADER:
            {
                return {
                    ...state,
                    subloader: false
                }
            }
        case DELIVERY_QUALIFICATION_RESULT:
            {
                return {
                    ...state,
                    lvrQualResult: action.payload
                }
            }
        case ASSEMBLY_QUALIFICATION_RESULT:
            {
                return {
                    ...state,
                    asbQualResult: action.payload
                }
            }
        case PRODUCT_QUALIFICATION_RESULT:
            {
                return {
                    ...state,
                    prodQualResult: action.payload
                }
            }
        default:
            return state;
    }
}