import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  icon: {
    borderRadius: "50%",
    width: 16,
    height: 16,
    backgroundColor: "#fff",
    border: "2px solid #00b5ad",
  },
  checkedIcon: {
    backgroundColor: "#00b5ad",
    border: "none",
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 16,
      height: 16,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  },
  receptionChoices: {
    marginBottom: 20,
  },
  helperText: {
    fontSize: "1rem",
    padding: "0 30px",
  },
  goBack: {
    textDecoration: "underline",
    display: "block",
    marginTop: 20,
    color: "#c52632",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default useStyles;
