import React from "react";
import { FormControl, InputLabel, Select, MenuItem } from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

const ExtractsChoice = ({
  extractsAvailable,
  extractSelect,
  changeExtract,
}) => {
  return extractsAvailable.length === 0 ? (
    <span>No hay extractos disponibles</span>
  ) : (
    <FormControl>
      <span className="select-tip">Selecciona un periodo</span>
      <InputLabel id="select-label">MM/AAAA</InputLabel>
      <Select
        onChange={changeExtract}
        id="extractSelect"
        IconComponent={ExpandMoreIcon}
        value={extractSelect}
      >
        {extractsAvailable.map((extract) => (
          <MenuItem key={extract.id} value={extract.id}>
            {extract.month}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default ExtractsChoice;
