import React from "react";
import Button from '@material-ui/core/Button';
import Spinner from 'react-bootstrap/Spinner';

export default class BtnRed extends React.Component {

    render() {
        const btnPrimaryApp = {
            backgroundColor: "#c2232f",
            color: "white",
            borderRadius: "22px",
            fontWeight: "600",
            fontFamily: "OpenSans Regular",
            textTransform: "none",
        };
        return (
            <span>
                {this.props.isLoggedIn ? <Button type="button" style={btnPrimaryApp} className={"btn btn-save"} disabled><Spinner as="span" animation="grow" size="sm" role="status" aria-hidden="true" />&nbsp; Validando... </Button> : <Button type="submit" style={btnPrimaryApp} className={"btn btn-save"} variant="contained" disabled={this.props.disabled} color="primary"> {this.props.title} </Button>}
            </span>
        )
    }
}